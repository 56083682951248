@if(this.successMessage?.message?.length > 0){
<div class="alert alert-success alert-dismissible fade show alert-section"
    [ngClass]="this.successMessage?.type === 'error' ? 'error': 'success'">
    @if(this.successMessage?.type === 'success'){
    <i class="bi bi-check-circle p-1 pt-0 pb-0 "></i>
    }@else if(this.successMessage?.type === 'error'){
    <i class="bi bi-x-circle"></i>
    }
    {{ this.successMessage?.message}}
</div>
}

<div class="container-fluid">
    <div class="row flex-nowrap">
        <div class="col-auto col-md-3 col-xl-2 px-0 side-nav-container">
            <app-side-nav></app-side-nav>
        </div>
        <div class="col content-sec-container pb-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>




<!-- <div class="lang-selector">
    <select (change)="changeLang()" [(ngModel)]="selectedLang">
        <option [value]="'en'">English</option>
        <option [value]="'fr'">French</option>
        <option [value]="'tr'">Turkish</option>
    </select>
    <h1>{{'app.welcome' | translate}}, {{ title }}</h1>
</div>


<div class="shift-data">
    <h3>Shift Position</h3>
    @for(position of shiftPositionData; track position.id) {
    <ul>
        <li>{{position.name}}</li>
    </ul>
    }
    <button class="btn btn-primary" #load>Login</button>
    @defer(on interaction(load)){
    <app-login></app-login>
    }
</div> -->
