import { HttpHeaders } from '@angular/common/http';

export function getHttpHeaders(headerObj: any) {
  let httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  let headers: HttpHeaders = new HttpHeaders();

  for (let i = 0; i < Object.keys(headerObj).length; i++) {
    const headerKey: any = Object.keys(headerObj)[i];
    const headerValue: any = Object.values(headerObj)[i];
    headers = headers.append(headerKey, headerValue);
  }
  httpOptions.headers = headers;
  return httpOptions;
}
