export function getLanguageList(languageDataList: any) {
  if (languageDataList && languageDataList.result.length > 0) {
    let languageList = [];
    for (let item of languageDataList.result) {
      languageList.push({
        id: item.code,
        value: item.code,
        name: item.name,
        text: item.name,
      });
    }
    return languageList;
  } else {
    return [];
  }
}
