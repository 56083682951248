import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightsService {
  private readonly angularPlugin = new AngularPlugin();
  private readonly appInsights: ApplicationInsights | undefined;
  private readonly router = inject(Router);

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightInstrumentationKey,
        extensions: [
          this.angularPlugin,
        ],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new ErrorHandler()],
          },
        },
      },
    });

    this.appInsights.loadAppInsights();
  }

  trackEvent(name: string) {
    this.appInsights!.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this.appInsights!.trackTrace({ message });
  }

  trackException(ex : Error) : void{
    this.appInsights!.trackException({exception : ex});
  }
}