import {
  Configuration,
  BrowserCacheLocation,
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import { environment } from '../../../environments/environment';

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.clientId,
    authority: `https://login.microsoftonline.com/${environment.tenantId}/oauth2/v2.0/`,
    knownAuthorities: ['https://login.microsoftonline.com'],
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri,
  },

  cache: {
    cacheLocation: BrowserCacheLocation.MemoryStorage,
    storeAuthStateInCookie: true
  },
};

export const loginRequest = {
  scopes: [],
};

export const protectedResources = {
  todoListApi: {
    endpoint: environment.apiUrl + '/',
    scopes: ['user.read'],
  },
};

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MsalGuardConfigurationFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [
        protectedResources.todoListApi.endpoint,
        protectedResources.todoListApi.scopes,
      ],
    ]),
  };
}
