import { Routes } from '@angular/router';
import { HomeComponent } from './core/components/home/home.component';
import { LoginComponent } from './core/components/layout/login/login.component'; 
import { MsalGuard } from '@azure/msal-angular'; 
import { PreviousHandoffComponent } from './features/DDDS/components/Handoff/components/previous-handoff/previous-handoff.component';
import { LogoutComponent } from './core/components/layout/logout/logout.component'; 

export const routes: Routes = [
    { path: '', component: LoginComponent, canActivate: [MsalGuard] },
    { path: 'logout', component:LogoutComponent },
    { path: 'huddle-board', loadComponent: () => import('./features/DDDS/components/huddle-board/huddle-board.component').then(m => m.HuddleBoardComponent), canActivate: [MsalGuard] },
    {
        path: 'landing-page',
        component: HomeComponent,
        canActivate: [MsalGuard],
        children: [
            {
                path: 'current',
                loadComponent: () => import('./features/DDDS/components/Handoff/components/current-handoff/current-handoff.component').then(m => m.CurrentHandoffComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'previous',
                loadComponent: () => import('./features/DDDS/components/Handoff/components/previous-handoff/previous-handoff.component').then(m => m.PreviousHandoffComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'task-library',
                loadComponent: () => import('./features/dcid/components/library-of-tasks/library-of-tasks.component').then(m => m.LibraryOfTasksComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'edit-task',
                loadComponent: () => import('./features/dcid/components/library-of-tasks/edit-task/edit-task.component').then(m => m.EditTaskComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'huddle-board',
                loadComponent: () => import('./features/DDDS/components/huddle-board/huddle-board.component').then(m => m.HuddleBoardComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'defect-log',
                loadComponent: () => import('./features/DH/components/defect-log/defect-log.component').then(m => m.DefectLogComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'dds-meeting',
                loadComponent: () => import('./features/DDDS/components/dds-meeting/dds-meeting.component').then(m => m.DdsMeetingComponent),
                canActivate: [MsalGuard]
            },
            
            {
                path: 'reports',
                loadComponent: () => import('./features/DDDS/components/report/reports/reports.component').then(m => m.ReportsComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'settings',
                loadComponent: () => import('./features/user-profile/settings.component').then(m => m.SettingsComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'recipe-catalogue',
                loadComponent: () => import('./features/DCL/components/recipe-catalogue/catalogue/catalogue.component').then(m => m.CatalogueComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'production-scheduled',
                loadComponent: () => import('./features/DCL/components/Schedules/production-scheduled/production-scheduled.component').then(m => m.ProductionScheduledComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'live-schedule',
                loadComponent: () => import('./features/DCL/components/Schedules/live-schedule/live-schedule.component').then(m => m.LiveScheduleComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'bin-raw-attribute',
                loadComponent: () => import('./features/DCL/components/Schedules/bin-raw-attribute/bin-raw-attribute.component').then(m => m.BinRawAttributeComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'library-routes',
                loadComponent: () => import('./features/dcid/components/library-of-routes/library-of-routes.component').then(m => m.LibraryOfRoutesComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'route-preview',
                loadComponent: () => import('./features/dcid/components/route-preview/route-preview.component').then(m => m.RoutePreviewComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'route-completion-status',
                loadComponent: () => import('./features/dcid/components/route-completion-status/route-completion-status.component').then(m => m.RouteCompletionStatusComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'summary-view',
                loadComponent: () => import('./features/dcid/components/summary-view/summary-view.component').then(m => m.SummaryViewComponent),
                canActivate: [MsalGuard]
            },
            {
                path: 'current-routes',
                loadComponent: () => import('./features/dcid/components/current-route/current-route.component').then(m => m.CurrentRouteComponent),
                canActivate: [MsalGuard]
            },
            {
                path: '',
                component: PreviousHandoffComponent,
                canActivate: [MsalGuard],
                pathMatch: 'full'
            },
            {
                path: 'product-sku',
                loadComponent: () => import('./features/DCL/components/product-sku/product-sku.component').then(m => m.ProductSkuComponent),
                canActivate:[MsalGuard]
            },
            {
                path: 'recipe',
                loadComponent: () => import('./features/DCL/components/recipe/recipe.component').then(m => m.RecipeComponent),
                canActivate:[MsalGuard]
            }, 
            {
                path: 'packaging',
                loadComponent: () => import('./features/DCL/components/recipe-catalogue/packaging/packaging.component').then(m => m.PackagingComponent),
                canActivate:[MsalGuard]
            } ,
            {
                path: 'centerline-overview',
                loadComponent: () => import('./features/DCL/components/centerline-overview/centerline-overview.component').then(m => m.CenterlineOverviewComponent),
                canActivate:[MsalGuard]
            } ,
            {
                path: 'deviation-history',
                loadComponent: () => import('./features/DCL/components/centerline-overview/deviation-history/deviation-history.component').then(m => m.DeviationHistoryComponent),
                canActivate:[MsalGuard]
            }
        ]
    }
];