import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import moment from 'moment';
import { ReportedDefectUiComponent } from '../../../reported-defect-ui/reported-defect-ui.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-equipment-defects',
  standalone: true,
  imports: [CommonModule, ReportedDefectUiComponent, TranslateModule],
  templateUrl: './equipment-defects.component.html',
  styleUrl: './equipment-defects.component.scss'
})
export class EquipmentDefectsComponent {
  @Input() equDefectResponse: any;
  @Input() reportedDefectArray: any = [];
  public reportedCategoryDefectArray = [];
  @Input() selectedPlantCode: any;
  @Input() positionCode: any;
  public moment: any = moment;


  ngOnChanges() {
  
    if (this.selectedPlantCode == 'FVL') {
      if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
        this.reportedCategoryDefectArray = this.reportedDefectArray.filter((eachItem: any) => eachItem.impact?.code == 'DEL' || eachItem.impact?.code == 'CST' || eachItem.impact?.code == 'PPL' || eachItem.impact?.code == 'ENV');
      }
    }
    else if (this.selectedPlantCode != 'FVL') { 
      if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
        this.reportedCategoryDefectArray = this.reportedDefectArray.filter((eachItem: any) => eachItem.impact?.code == 'DEL' || eachItem.impact?.code == 'CST' || eachItem.impact?.code == 'PPL');
      }
    }
  }

}
