import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-cid',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './cid.component.html',
  styleUrl: './cid.component.scss'
})
export class CidComponent {
  @Input() cidResponse: any
  @Input() CIDdata:any;


}
