import {
  AfterViewInit,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataService } from '../../../../shared/services/data-service.service';
import { CommonModule, NgClass } from '@angular/common';
import { AuthApiService } from '../../../services/auth-api.service';
import * as APP_CONST from '../../../../../app/constants/app.constants';
import { getUserDetails } from '../../../../helper/getUserDetails';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import { ModalService } from '../../../../shared/components/modalpopup/modalservice';
import { DclApiService } from '../../../../features/services/dcl-api.service';
import { IDeviationDataCountResponse, IGetDeviationHistory, ISelectedPosition } from '../../../../features/DCL/components/models/deviationHistory';

@Component({
  selector: 'app-side-nav',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    RouterLinkActive,
    CommonModule,
    NgClass,
  ],
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
})
export class SideNavComponent implements OnInit, OnDestroy, AfterViewInit {
  private router = inject(Router);
  private authapiService = inject(AuthApiService);
  public roles: any;
  public userProfileName: any;
  public privilegeCode: string[] = [];
  public privilegeCodeTemp: string[] = [];
  public userDetailsData: any;

  public SelectedPositionItems: Array<ISelectedPosition> = [];
  plantId: string = '';
  plantName: string = '';

  public checkIfPlantLead: any;
  public plantCode: any;
  public interval: any;
  public isLoading = false;
  public currentShiftInfo: any;
  public showCurrentRoute: any;
  public showTaskLibrary: any;
  public showRouteLibrary: any;
  public showRoutePreview: any;
  public showRCStatus: any;
  public showCommentSum: any;
  public sideNavOrder: any = [
    'DDS_PREHOF',
    'DDS_CURHOF',
    'DDS_REPORT',
    'CID_HOME',
    'CID_LIBTAS',
    'CID_LIBTAS_VW',
    'CID_LIBROU_PREV',
    'CID_CURCID',
    'CID_LIBROU',
    'CID_RCSTAT',
    'CID_COMMSU',
    'DDH_DEFCT_SOR',
    'DCL_PRODSHDL',
    'DCL_RECPCTLG_PREV',
    'DDS_HADLBD',
    'DDS_MTNG',
    'DDS_SETTNG',
    'DCL_DEVNHSTR',
    'DCL_BINRAW'
  ];
  subscription: any;
  activeParentId: string = '';
  deviationCount: number = 0;
  constructor(
    private modalHelperService: ModalService,
    private dataService: DataService,
    private _ngZone: NgZone,
    private authService: MsalService,
    private dcl: DclApiService,
    public renderer: Renderer2
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkActiveRoute();
        this.getDeviationHistoryCount();
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkActiveRoute();
  }
  onLogout(appLogout?: any) {
    this.renderer.addClass(document.body, 'overlay-logout-class');
    //this.isLoading = true;
    const env = environment;
    this.userDetailsData = JSON.parse(
      sessionStorage.getItem('userDetails') || ''
    );
    const userDetailsInfoData = getUserDetails(this.userDetailsData);
    this.authapiService
      .logout(
        APP_CONST.AUTH_FEATURE,
        APP_CONST.USER_SUBFEATURE,
        userDetailsInfoData
      )
      .subscribe({
        next: (logoutDataResponse: any) => {
          console.log(logoutDataResponse);
        },
        complete: () => {
          if (appLogout == true) {
            localStorage.removeItem('token');
            this.authService.logout();
            //this.isLoading = false;
            this.authService.logoutRedirect({
              postLogoutRedirectUri: env.redirectUri,
            });
            localStorage.removeItem('filterData');
            localStorage.removeItem('sortData');
          } else {
            //.isLoading = false;
            this.modalHelperService.dismissAllModals();
            this.router.navigate(['']);
          } 
          this.renderer.removeClass(document.body, 'overlay-logout-class');
        },
        error: (response) => {
          console.log('error', response);
         // this.isLoading = false;
          this.router.navigate(['']);
          this.renderer.removeClass(document.body, 'overlay-logout-class');
        },
      });

  }
  ngOnInit(): void {
    this.SelectedPositionItems = JSON.parse(
      sessionStorage.getItem('SelectedItemsForKPI') || ''
    );

    this.checkIfPlantLead =
      this.SelectedPositionItems.length == 1 &&
      this.SelectedPositionItems.findIndex(
        (x: { value: string }) => x.value == 'PLDR'
      ) >= 0;
    this.subscription = this.dataService.userProfileInfo.subscribe(
      (data: any) => {
        this.userProfileName = data.userName;
        this.roles = data.roles;
        sessionStorage.setItem('userName', this.userProfileName);
        if (this.userProfileName) {
          this.checkTimerLogic();
        }
      }
    );
    const userDetails: any = sessionStorage.getItem('userDetails');
    const privCode = JSON.parse(userDetails);
    this.plantCode = this.mapPlantIdName(privCode);
    this.privilegeCodeTemp = this.removeDuplicates(privCode.result.privileges);
    this.privilegeCode = [];
    this.sideNavOrder.forEach((element: any) => {
      if (this.privilegeCodeTemp.includes(element)) {
        this.privilegeCode.push(element);
      }
    });

    if (!this.userProfileName && !this.roles) {
      const userDetailsData = JSON.parse(userDetails);
      const userName =
        userDetailsData && userDetailsData.userProfileName
          ? userDetailsData.userProfileName
          : '';
      this.userProfileName = userName;
      this.roles = sessionStorage.getItem('UserRolesList');
    }
    if (this.privilegeCode.includes('CID_CURCID')) {
      this.showCurrentRoute = true;
    }
    if (
      this.privilegeCode.includes('CID_LIBTAS') ||
      this.privilegeCode.includes('CID_LIBTAS_VW')
    ) {
      this.showTaskLibrary = true;
    }
    if (this.privilegeCode.includes('CID_LIBROU')) {
      this.showRouteLibrary = true;
    }
    if (this.privilegeCode.includes('CID_LIBROU_PREV')) {
      this.showRoutePreview = true;
    }
    if (this.privilegeCode.includes('CID_RCSTAT')) {
      this.showRCStatus = true;
    }
    if (this.privilegeCode.includes('CID_COMMSU')) {
      this.showCommentSum = true;
    }
    if (sessionStorage.getItem('CurrentShiftInfo') !== null) {
      this.currentShiftInfo = JSON.parse(
        sessionStorage.getItem('CurrentShiftInfo') || ''
      );
    }
    if (this.currentShiftInfo != null) {
      this.checkTimerLogic();
    } else {
      let isAutoLoggedout = false;
      if (sessionStorage.getItem('autoLoggedOut') !== null) {
        isAutoLoggedout = JSON.parse(
          sessionStorage.getItem('autoLoggedOut') || ''
        );
      }
      if (isAutoLoggedout == true) {
        this.router.navigate(['']);
      }
    }
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    const selectedPlant = this.getSelectedPlant(this.userDetailsData.result.prePlantId);
    this.plantId = selectedPlant?.plantId || "";
    this.plantName = selectedPlant?.plantName || "";

    this.getDeviationHistoryCount();
  }

  getSelectedPlant(id: string) {
    if (!this.userDetailsData) {
      return;
    }
    const plantName = this.userDetailsData.result.plants.find(
      (item: { id: string }) => item.id === id
    );
    return {plantName: plantName ? plantName.name : null, plantId: plantName ? plantName.id : null };
  }

  mapPlantIdName(userDetailsObj: any) {
    let plantCode;
    userDetailsObj.result.plants.forEach((element: any) => {
      if (element.id == userDetailsObj.result.prePlantId) {
        plantCode = element.code;
      }
    });
    return plantCode;
  }
  ngOnDestroy(): void {
    if(this.subscription){
    this.subscription.unsubscribe();
    }
    clearInterval(this.interval); 
  }
  removeDuplicates(arr: any) {
    const unique: any = [];
    arr.forEach((element: any) => {
      if (!unique.includes(element)) {
        unique.push(element);
      }
    });
    return unique;
  }

  checkTimerLogic() {
    if (!this.disableAutologoutForKpi()) {
      if (sessionStorage.getItem('CurrentShiftInfo') !== null) {
        this.currentShiftInfo = JSON.parse(
          sessionStorage.getItem('CurrentShiftInfo') || ''
        );
      } else {
        this.currentShiftInfo = null;
      }
      if (this.currentShiftInfo != null) {
        const currenttime = moment.utc().unix();
        const shiftendUtc = this.currentShiftInfo?.currShiftUtcEndTimeEpoch
          ? parseInt(this.currentShiftInfo?.currShiftUtcEndTimeEpoch)
          : 0;
        const currentUtc = moment.utc().unix() * 1000;
        if (shiftendUtc > currentUtc) {
          this.startTimer(shiftendUtc - currentUtc);
        } else {
          this.startTimer(0);
        }
      }
    }
  }

  disableAutologoutForKpi() {
    let kpiIndex = -1;
    if (this.SelectedPositionItems && this.SelectedPositionItems.length == 1) {
      kpiIndex = this.SelectedPositionItems.findIndex(
        (x: { value: string | string[] }) => x.value.includes('KPIV')
      );
    }
    if (kpiIndex >= 0) {
      return true;
    } else {
      return false;
    }
  }
  checkforLogout() {
    if (sessionStorage.getItem('CurrentShiftInfo') !== null) {
      this.currentShiftInfo = JSON.parse(
        sessionStorage.getItem('CurrentShiftInfo') || ''
      );
    } else {
      this.currentShiftInfo = null;
    }
    if (this.currentShiftInfo != null) {
      const shiftendUtc = this.currentShiftInfo?.currShiftUtcEndTimeEpoch
        ? parseInt(this.currentShiftInfo?.currShiftUtcEndTimeEpoch)
        : 0;
      const currentUtc = moment.utc().unix() * 1000;
      if (shiftendUtc <= currentUtc) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  startTimer(timediff: any) {
    this.interval = setInterval(() => {
      if (this.checkforLogout() || timediff == 0) {
        this.dataService.autoLogoutStatus.next({ isAutoLogout: true });
        this.onLogout(false);
        clearInterval(this.interval);
      }
    }, timediff);
  }

  checkActiveRoute = () => {
    const currentUrl = this.router.url;
    const nestedSubMenu = document.querySelectorAll('.nav-multi-item.nav-item');
    nestedSubMenu.forEach((submenu) => {
      const childLinks = submenu.querySelectorAll('ul.collapse.nav li a');
      let isActive = false;
      childLinks.forEach((child) => {        
        if (
          currentUrl.includes(
            (child as HTMLAnchorElement).getAttribute('href') as string
          )
        ) {          
          isActive = true;
        }
      });
      if (isActive) {
        const submenuHeadId = submenu
          .querySelector('a.nav-link.align-middle.multi-level-dropdown')
          ?.getAttribute('id');
        this.setParentActive(submenuHeadId as string);
      } 
    });   
  };
  setParentActive = (id: string) => {
    this.activeParentId = id;
  };

  getDeviationHistoryCount = () => {
    const positions = this.SelectedPositionItems.map(
      (selectedPosition: ISelectedPosition) => selectedPosition.id.toString()
    );
    this.dcl
      .getDeviationDataCount(
        getUserDetails(this.userDetailsData),
        this.plantId,
        this.plantName,
        positions,
      )
      .subscribe({
        next: (response: IDeviationDataCountResponse) => {
          if (response.isSuccess && response.deviation !== null) {
            this.deviationCount = response.deviation;
          } else {
            this.deviationCount = 0;
          }
        },
        error: (error) => {
          console.log('Error while fetching packaging sensor data:', error);
        },
      });
  };
}
