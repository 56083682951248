import { Injectable } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalRefs: BsModalRef[] = [];

  constructor() {}

  addModalRef(modalRef: BsModalRef) {
    this.modalRefs.push(modalRef);
  }

  dismissAllModals() {
    this.modalRefs.forEach(modalRef => {
      modalRef.hide();
    });
    this.modalRefs = [];
  }
}
