export function isAccessTokenExpired(): Boolean {
  let accesstoken: any = JSON.parse(sessionStorage.getItem('Apitoken') || '');
  let accessTokenExpirationTime: any = accesstoken.tokenExpiryTime;
  accessTokenExpirationTime = new Date(accessTokenExpirationTime);
  const epochNow = new Date();
  const currentTimeMillis = epochNow;
  return accessTokenExpirationTime != null && currentTimeMillis <= accessTokenExpirationTime
}

export function getAccessToken(): any {
  let accesstoken = JSON.parse(sessionStorage.getItem('Apitoken') || '');
  return accesstoken.token;
}

export function updateRequestWithToken(request: any): any {
  let accesstoken: any = JSON.parse(sessionStorage.getItem('Apitoken') || '');
  request = request.clone({
    headers: request.headers.append('Apitoken', accesstoken.token)
  });
  return request;
}
