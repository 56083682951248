@if(isSupervisorPosition) {
    <div class="handoff-container quality-prev">
        <div class="row spacing-pt-20">
            <div class="col-6 items-section">
                <h3>{{ "app.previous_hoff.items" | translate }}</h3>
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                'number') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{controlItem1.response != ' ' && moment(controlItem1.response ,moment.ISO_8601,true).isValid() ? (controlItem1.response | date : 'MM/dd/yyyy h:mm a') : controlItem1.response}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
                }
                }
            </div>
            <div class="col-6 comments-section">
                <h3>{{ "app.previous_hoff.comments" | translate }}</h3>
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
                @if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "inventory-tote-cmt") {
                    <div class="row spacing-pt-20 ">
                        <div class="col"></div>
                    </div>
                    <div class="row two-line-spacing">
                        <div class="col response-sec">
                            <p class="mb-0">{{controlItem1.response ? controlItem1.response: ''}}</p>
                        </div>
                    </div>
                } @else if(controlItem1.ctrltype === 'text') {
                    <div class="row spacing-pt-20 ">
                        <div class="col"></div>
                    </div>
                    <div class="row spacing-pt-20">
                        <div class="col response-sec">
                            <p class="mb-0">{{controlItem1.response ? controlItem1.response: ''}}</p>
                        </div>
                    </div>
                    } 
                }
                }
    
            </div>
    
        </div>
        <div class="row spacing-pt-20">
            <div class="col">
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
    
                @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label"){
                    <div class="col p-0">
                        <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header">
                            <label
                                class=" form-control h-75 label-head rounded-0  d-flex ">{{controlItem1.labeltext}}</label>
                        </div>
                    </div>
            
                    }
    
                @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                <div class="row py-3 ">
                    <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                </div>
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
                </div>
                }
                }
                }
    
            </div>
    
        </div>
    </div>
    } @else if(isOperatorPosition) {
    @if(positionCode === 'SPLS') {
    <div class="handoff-container category-container">
        <div class="row spacing-pt-10">
            <div class="col-6 items-section">
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype ==='number') {
                @if(controlItem1.controlname === "last-cleaned-rifle-plate") {
                <div class="row spacing-pt-10">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{getTime(controlItem1.response)}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
                }
                }
                }
            </div>
            <div class="col-6 comments-section">
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                'number') {
                @if(controlItem1.controlname === "last-cleaned-cutters") {
                <div class="row spacing-pt-10">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec mt-0">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{getTime(controlItem1.response)}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
                } @else if(controlItem1.ctrltype === 'text') {
                <div class="row spacing-pt-10 ">
                    <div class="col"></div>
                </div>
                <div class="row spacing-pt-10">
                    <div class="col response-sec">
                        <p class="mb-0">{{controlItem1.response}}</p>
                    </div>
                </div>
                }
    
                }
                }
    
            </div>
    
        </div>
    
        <div class="row spacing-pt-10">
            <div class="col">
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
    
                @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                <div class="row py-3 ">
                    <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                </div>
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
                </div>
                }
                }
                }
    
            </div>
    
        </div>
    
        <div class="row  spacing-pt-10">
            <div class="col-6 items-section">
                @for(item of otherIssueResponse; track item; let i= $index) {
                @for (controlItem1 of otherIssueResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton') {
                <div class="row">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>
    
                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{(controlItem1.response != null && controlItem1.response != ' ' && moment(controlItem1.response ,moment.ISO_8601,true).isValid())?(controlItem1.response| date : 'MM/dd/yyyy h:mm a') :controlItem1.response}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>
    
                        }
                    </div>
                </div>
                }
                }
                }
            </div>
        </div>
    </div>
    }@else if(positionCode.includes('PKG')) {
    
        <div class="handoff-container quality-prev">
            <div class="row spacing-pt-20">
                <div class="col-6 items-section">
                    <h3 class="mb-0 ">{{ "app.previous_hoff.items" | translate }}</h3>
                    @for(item of otherIssueResponse; track item; let i= $index) {
                    @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
                    @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype ===
                    'number') {
                    <div class="row spacing-pt-20">
                        <div class="col">
                            <label class="label">{{controlItem1.labeltext}}</label>
                        </div>
        
                    </div>
                    <div class="row  m-0">
                        <div class="col response-sec">
                            @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                            <p class="mb-0">{{controlItem1.response!= ' '?(controlItem1.response | date : 'MM/dd/yyyy h:mm a') : controlItem1.response}}</p>
                            } @else {
                            <p class="mb-0">{{controlItem1.response}}</p>
        
                            }
                        </div>
                    </div>
                    }
                    }
                    }
                </div>
                <div class="col-6 comments-section">
                    <h3 class="mb-0 ">{{ "app.previous_hoff.comments" | translate }}</h3>
                    @for(item of otherIssueResponse; track item; let i= $index) {
                    @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
                    @if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "inventory-tote-cmt") {
                        <div class="row spacing-pt-20 ">
                            <div class="col"></div>
                        </div>
                        <div class="row two-line-spacing">
                            <div class="col response-sec">
                                <p class="mb-0">{{controlItem1.response ? controlItem1.response: ''}}</p>
                            </div>
                        </div>
                    } @else if(controlItem1.ctrltype === 'text') {
                        <div class="row spacing-pt-20 ">
                            <div class="col"></div>
                        </div>
                        <div class="row spacing-pt-20">
                            <div class="col response-sec">
                                <p class="mb-0">{{controlItem1.response ? controlItem1.response: ''}}</p>
                            </div>
                        </div>
                        } 
                    }
                    }
        
                </div>
        
            </div>
            <div class="row spacing-pt-20">
                <div class="col">
                    @for(item of otherIssueResponse; track item; let i= $index) {
                    @for (controlItem1 of otherIssueResponse[i+1]; track controlItem1) {
        
                    @if(controlItem1.widget ==='input-table-header' && controlItem1.ctrltype === "label"){
                        <div class="col p-0">
                            <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header">
                                <label
                                    class=" form-control h-75 label-head rounded-0  d-flex ">{{controlItem1.labeltext}}</label>
                            </div>
                        </div>
                
                        }
        
                    @if(controlItem1.ctrltype === 'label' && controlItem1.type ==='defect' ){
                    <div class="row py-3 ">
                        <label class="safety-header">{{ "app.previous_hoff.reported_safety_issues" | translate }}</label>
                    </div>
                    <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                        <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
                    </div>
                    }
                    }
                    }
        
                </div>
        
            </div>
        </div>
    }@else if(positionCode === 'SPLFRY' || positionCode === 'SPLMIX') {
    <div class="handoff-container category-container spl-frying-container">
        @for (controlItem of otherIssueResponse; track controlItem;) {
        <div class="row">
            @for (equStopsContentItem of controlItem; track equStopsContentItem) {
    
            @if(equStopsContentItem.widget ==='input-table-header' && equStopsContentItem.ctrltype === "label"){
            <div class="col p-0">
                <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{equStopsContentItem.controlname}}">
                    <label
                        class=" form-control h-75 label-head rounded-0  d-flex ">{{equStopsContentItem.labeltext}}</label>
                </div>
            </div>
    
            }
    
    
            @if(equStopsContentItem.widget ==='input-table' || equStopsContentItem.ctrltype === "text"){
            @if(equStopsContentItem.value) {
            <div class="col p-1 target-container">
                <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                    {{equStopsContentItem.value}}
                   
                </div>
            </div>
            }
    
            }
    
            @if(equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "number")
            {
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-10">
                        {{equStopsContentItem.response}}
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">
    
                        {{equStopsContentItem.unit}}
                    </div>
                    }
                   
                </div>
            </div>
            }
    
            @if(equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "rdbutton")
            {
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-10">
                        {{equStopsContentItem.response}}
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">
    
                        {{equStopsContentItem.unit}}
                    </div>
                    }
                  
                </div>
            </div>
            }
    
            @if((equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "text") &&
            (equStopsContentItem.labeltextcmt || equStopsContentItem.controlname === "metal-dtctr-istarget-cmt" ||
            equStopsContentItem.controname === "metal-dtctr-comments" || equStopsContentItem.controlname === "fryer-color-istarget-cmt" 
            || equStopsContentItem.controlname === "blancher-ph-istarget-cmt" || equStopsContentItem.controlname === "blancher-color-istarget-cmt" || equStopsContentItem.controlname === "fryer-solid-istarget-cmt"
            || equStopsContentItem.controlname === "fryer-ffa-istarget-cmt" || equStopsContentItem.controlname === "fryer-piece-count-istarget-cmt" || equStopsContentItem.controlname === "fryer-weight-istarget-cmt" || equStopsContentItem.controlname === "fryer-grade-istarget-cmt"))
            {
            <div class="col p-1 target-container">
                <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                    <div class="d-flex align-items-center col-10">
                        
                        @if(equStopsContentItem.response) {
                            {{equStopsContentItem.response}}
                        }@else {
                            
                        }
                    </div>
                    @if(equStopsContentItem.unit) {
                    <div class="d-flex align-items-center justify-content-end unit-bg">
    
                        {{equStopsContentItem.unit}}
                    </div>
                    }
                  
                </div>
                
            </div>
            @if(equStopsContentItem.ctrltype == "flagicon")
                { 
                <span
                  class="flagclass" > 
                </span>
                }
            }
    
            @if((equStopsContentItem.ctrltype === "label" && equStopsContentItem.controlname === "quality-item"))
            {
            <div class="col p-1">
                <div class="col  p-0 d-flex">
                    
                    
                        <div class="row py-3 px-0">
                            <label class="safety-header">{{equStopsContentItem.labeltext}}</label>
                        </div>
    
                    
                </div>
            </div>
            }
    
            @if(equStopsContentItem.ctrltype === "label" && equStopsContentItem.controlname === "quality-comment")
            {
            <div class="col p-1">
                <div class="col  p-0 d-flex">
                    <div class="row py-3 px-0">
                        <label class="safety-header">{{equStopsContentItem.labeltext}}</label>
                    </div>
                    <div class="row">
                        
                    </div>
    
    
                  
                </div>
            </div>
            }
         
    
            
            @if(equStopsContentItem.ctrltype === 'number' && equStopsContentItem.controlname === "metal-dtctr-prdct-loss") {
                <div class="col">
                    <div class="row">
                        <div class="col px-0">
                            <label class="label">{{equStopsContentItem.labeltext}}</label>
                        </div>
            
                    </div>
                    <div class="row label-container">
                        <div class="col response-sec ">
        
                            <p class="mb-0">{{equStopsContentItem.response}}
                                @if(equStopsContentItem.unit) {
                                    <span class="unit-bg unit-kg">
                    
                                        {{equStopsContentItem.unit}}
                                    </span>
                                }
                            </p>
                           
        
                        </div>
                        
                    </div>
                </div>
                
            }
    
            
 
    
            @if(equStopsContentItem.ctrltype === 'date' && equStopsContentItem.controlname === "last-cleaned-date") {
                <div class="col">
                    <div class="row">
                        <div class="col px-0">
                            <label class="label">{{equStopsContentItem.labeltext}}</label>
                        </div>
            
                    </div>
                    <div class="row label-container">
                        <div class="col response-sec ">
                            <p class="mb-0">{{equStopsContentItem.response != ' ' && moment(equStopsContentItem.response ,moment.ISO_8601,true).isValid() ?(equStopsContentItem.response| date : 'MM/dd/yyyy h:mm a'):equStopsContentItem.response}}</p>
        
                        </div>
                    </div>
                </div>
                
            }
    
            @if(equStopsContentItem.ctrltype === "text" && equStopsContentItem.controlname === "last-cleaned-date-cmt") {
                <div class="col">
                    <div class="row no-label label-container">
                        <div class="col response-sec ">
        
                            <p class="mb-0">{{equStopsContentItem.response}}</p>
        
                        </div>
                    </div>
                </div>
        
            }
    
            @if(equStopsContentItem.ctrltype === "text" && equStopsContentItem.controlname === "metal-dtctr-prdct-loss-cmt") {
                <div class="col">
                    <div class="row no-label label-container">
                        <div class="col response-sec ">
        
                            <p class="mb-0">{{equStopsContentItem.response}}</p>
        
                        </div>
                    </div>
                </div>
        
            }
    
            
    
    
            @if(equStopsContentItem.controlname === "last-fr-inspect-date" || equStopsContentItem.controlname ===
            "last-tnl-inspect-date") {
            <div class="col px-0 date-lablel-container">
    
                <div class="row spacing-pt-10">
                    <div class="col">
                        <label class="label">{{equStopsContentItem.labeltext}}</label>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col response-sec d-flex align-items-center">
                        <p class="mb-0">{{equStopsContentItem.response!=' ' ? (equStopsContentItem.response | date : 'MM/dd/yyyy h:mm a'):equStopsContentItem.response }}</p>
                    </div>
                </div>
    
            </div>
            }
    
            @if(equStopsContentItem.ctrltype === 'label' && equStopsContentItem.type ==='defect' ){
            <div class="row py-3 px-0">
                <label class="safety-header">{{ "app.previous_hoff.reported_food_safety_quality_issues" | translate }}</label>
            </div>
            <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }}
            </div>
            }
    
            @if(equStopsContentItem.ctrltype === 'rdbutton' && equStopsContentItem.labeltext === "Any other Food Safety Quality(FSQ) observation or risk identified?") {
            <div class="row">
                <div class="col px-0">
                    <label class="label">{{equStopsContentItem.labeltext}}</label>
                </div>
    
            </div>
            <div class="row label-container">
                <div class="col-6 response-sec ">
                    @if(equStopsContentItem.ctrltype === 'date' || equStopsContentItem.ctrltype === 'number') {
                    <p class="mb-0">{{equStopsContentItem.response != ' ' && moment(equStopsContentItem.response ,moment.ISO_8601,true).isValid() ? (equStopsContentItem.response | date : 'MM/dd/yyyy h:mm a'):equStopsContentItem.response }}</p>
                    } @else {
                    <p class="mb-0">{{equStopsContentItem.response}}</p>
    
                    }
                </div>
            </div>
            }
    
            @if(equStopsContentItem.ctrltype === 'rdbutton' && equStopsContentItem.labeltext === "Any other Food Safety
            Quality(FSQ) observation or risk identified?") {
            <div class="row">
                <div class="col px-0">
                    <label class="label">{{equStopsContentItem.labeltext}}</label>
                </div>
    
            </div>
            <div class="row">
                <div class="col-6 response-sec ">
                    @if(equStopsContentItem.ctrltype === 'date' || equStopsContentItem.ctrltype === 'number') {
                    <p class="mb-0">{{equStopsContentItem.response | date : 'MM/dd/yyyy h:mm a' }}</p>
                    } @else {
                    <p class="mb-0">{{equStopsContentItem.response}}</p>
    
                    }
                </div>
            </div>
            }
    
    
            }
    
    
    
        </div>
        }
    </div>
    }
    @else if(positionCode === 'PL' || positionCode === 'MR') {
        <div class="handoff-container category-container spl-frying-container">
            @for (controlItem of otherIssueResponse; track controlItem;let i = $index) {            
            <div  
            [ngClass]="(otherIssueResponse[i][0]?.widget =='input-table-header') ? 'row quality-custom-table':'row'" class="{{(controlItem && controlItem?.[0])? controlItem[0]?.controlname:''}}">
                @for (equStopsContentItem of controlItem; track equStopsContentItem;let j = $index) {
        
                @if(equStopsContentItem.widget ==='input-table-header' && equStopsContentItem.ctrltype === "label"){
                <div class="col p-0">
                    <div class="col p-0 d-flex textbox-content  textbox-wrapper  label-header custom-{{equStopsContentItem.controlname}}">
                        <label
                            class=" form-control h-75 label-head rounded-0  d-flex ">{{equStopsContentItem.labeltext}}</label>
                    </div>
                </div> 
                } 
                @if(equStopsContentItem.widget ==='input-table' || equStopsContentItem.ctrltype === "text"){
                @if(equStopsContentItem.value) {
                <div class="col p-1 target-container">
                    <div class="col p-0 d-flex textbox-content textbox-wrapper align-items-center">
                        {{equStopsContentItem.value}}
                  </div>
                </div>
                }    
                } 
                @if(equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "rdbutton")
                {
                <div class="col p-1 target-container">
                    <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                        <div class="d-flex align-items-center col-10">
                            {{equStopsContentItem.response}}
                        </div>
                        @if(equStopsContentItem.unit) {
                        <div class="d-flex align-items-center justify-content-end unit-bg">
        
                            {{equStopsContentItem.unit}}
                        </div>
                        } 
                    </div>
                </div>
                }
        
                @if((equStopsContentItem.widget ==='input-table' && equStopsContentItem.ctrltype === "text") &&
                ( equStopsContentItem.controlname==="packaging-issue-cmt" || equStopsContentItem.controlname==="any-other-value" || equStopsContentItem.controlname==="is-material-value"
                || equStopsContentItem.controlname === "paperwork-issues-comments" ||  equStopsContentItem.controlname === "packaging-issues-comments"))
                {
                <div class="col p-1 target-container">
                    <div class="col  p-0 d-flex textbox-content textbox-wrapper">
                        <div class="d-flex align-items-center col-10">
                            
                            @if(equStopsContentItem.response) {
                                {{equStopsContentItem.response}}
                            } 
                        </div> 
                    </div>
                </div>
              
                } 
           
                 @if(equStopsContentItem.ctrltype == "flagicon")
        {
        <span class="enableFlag-{{equStopsContentItem.response ==true ? true:false}}">
        </span>
        } 
                @if(equStopsContentItem.ctrltype === 'label' && equStopsContentItem.type ==='defect' ){
                <div class="row py-3 px-0">
                    <label class="safety-header">{{equStopsContentItem.labeltext}}</label>
                </div>
                <div class="row pl-0 d-flex mt-0 zero-report-wrapper">
                    <span class=" safety-report-zero rounded-0"><i class="bi bi-ban icon-size"></i></span> 0 {{ "app.previous_hoff.reported" | translate }} -{{ "app.previous_hoff.confirmed" | translate }}
                </div>
                }
        
                @if(equStopsContentItem.ctrltype === 'rdbutton' && equStopsContentItem.labeltext === "Any other Food Safety Quality(FSQ) observation or risk identified?") {
                <div class="row">
                    <div class="col px-0">
                        <label class="label">{{equStopsContentItem.labeltext}}</label>
                    </div>
        
                </div>
                <div class="row label-container">
                    <div class="col-6 response-sec "> 
                        <p class="mb-0">{{equStopsContentItem.response}}</p> 
                    </div>
                </div>
                } 
                }  
        
            </div>
            }
        </div>
        }
    
    }