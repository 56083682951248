<div class="header" >
    <div class="user-profile-section">
        <app-user-side-nav [userDetailsProfileData]="userDetailsInfo"></app-user-side-nav>
    </div>
    <div class="environment-section">
        <div class="env-label justify-content-center align-items-center d-flex">
            <span class="env-text" [ngClass]="envColor">{{envCode}}</span> 
        </div>
    </div>
    <div class="environment-section">
        <div class="env-label justify-content-center align-items-center d-flex"> 
            <span class="env-text" [ngClass]="envColor">{{appVersion}}</span>
        </div>
    </div>
</div>