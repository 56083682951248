import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { inject } from '@angular/core';
import {ApplicationInsightsService} from '../../appInsight.service'
 
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly applicationInsightsService = inject(ApplicationInsightsService);
    constructor(private injector: Injector) { }    

    handleError(error: Error) {
       this.applicationInsightsService.trackException(error);
       console.log("error : ", error);
       console.log(error.stack);
    }
} 
