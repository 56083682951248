import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-show-confirm-message',
  standalone: true,
  imports: [],
  providers: [BsModalService],
  templateUrl: './show-confirm-message.component.html',
  styleUrl: './show-confirm-message.component.scss',
})
export class ShowConfirmMessageComponent implements OnInit {
  public onSave:any;
  public title: string = '';
  public message: string = '';
  public ok: string = '';
  public cancel: string = '';
  public modalRef1: any;
  

  constructor(
    public modalRef: BsModalRef,
    public modalService: BsModalService
  ) {}
  ngOnInit(): void {

    this.onSave = new Subject();
  }
  onClose() {
    this.onSave.next(false);
    this.modalRef.hide();
  }
  onSubmit() {
    this.onSave.next(true);
    this.modalRef.hide();
  }
}
