@if(isLoading) {
<div class="overlay d-flex align-items-center justify-content-center">
  <div class="overlay__inner">
    <div class="overlay__content">
      <span class="spinner"></span>
      <p>Loading...</p>
    </div>
  </div>
</div>
}
<div class="d-flex flex-column text-white min-vh-100 side-nav-bar">
  <div class="top-sec">
    <div class="logo d-flex justify-content-center">
      <img src="../../../../../assets/images/mccainLogo.png" alt="" />
    </div>
    <!-- Location,Plant and Line Section Start -->
    <div class="location-section d-flex align-items-center justify-content-center">
      <p class="selected-location">
        {{ "app.landing_page.location" | translate }}
      </p>
      <p class="selected-plant">{{ plantCode }}</p>
      <!-- <p class="selected-line">FRY-1</p> -->
    </div>
    <!-- Location,Plant and Line Section End -->
    <!-- User Data Section Start  -->
    <div class="user-details d-flex align-items-center">
      <!-- TODO required -->
      <div class="flex-shrink-0 user-image">
        <img src="../../../../../assets/images/user_icon.svg" alt="" />
      </div>
      <div>
        <p [ngClass]="
            userProfileName && userProfileName.length > 20
              ? 'user-name-small m-0'
              : 'user-name m-0'
          ">
          {{ userProfileName }}
        </p>
        <p [ngClass]="
            roles && roles.length > 3 ? 'user-role-small m-0' : 'user-role m-0'
          " data-toggle="tooltip" data-placement="top" title="{{ roles }}">
          {{ roles }}
        </p>
      </div>
    </div>
    <!-- User Data Section End  -->
  </div>
  <div class="mid-section">
    <!-- Menu Start -->

    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start nav-menu-section"
      id="menu">
      @for(code of privilegeCode; track $index) { @if(code === 'DDS_PREHOF' &&
      !checkIfPlantLead) {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'previous-handoff-' + $index" [routerLink]="'/landing-page/previous'" [routerLinkActive]="'active'">
          <span class="icon-text-wrapper">
            <i class="bi bi-arrow-left"></i><span class="ms-1 d-none d-sm-inline">{{'Previous Handoff' | translate}}</span>
          </span>
        </a>
      </li>
      } @if(code === 'DDS_CURHOF' && !checkIfPlantLead) {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'current-handoff-' + $index" [routerLinkActive]="'active'" [routerLink]="'/landing-page/current'">
          <span class="icon-text-wrapper">
            <i class="bi bi-arrow-right"></i>
            <span class="ms-1 d-none d-sm-inline">{{'Current Handoff' | translate}}</span>
          </span>
        </a>
      </li>
      } @if(code === 'DDS_REPORT' && !checkIfPlantLead) {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'reports-' + $index" [routerLink]="'/landing-page/reports'" [routerLinkActive]="'active'">
          <span class="icon-text-wrapper">
            <i class="bi bi-file-earmark-richtext"></i>
            <span class="ms-1 d-none d-sm-inline">{{'Reports' | translate}}</span>
          </span>
        </a>
      </li>
      } 
      <!-- will use later -->

      @if(code === 'DDS_HADLBD') {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'huddle-board-' + $index" [routerLink]="'/huddle-board'" [routerLinkActive]="'active'">
          <span class="icon-text-wrapper">
            <i class="bi bi-people"></i><span class="ms-1 d-none d-sm-inline">{{'Huddle Board' | translate}}</span>
          </span>
        </a>
      </li>
      } @if( code === 'DDS_MTNG') {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'DDS-meeting-' + $index" [routerLink]="'/landing-page/dds-meeting'" [routerLinkActive]="'active'">
          <span class="icon-text-wrapper">
            <i class="bi bi-people"></i><span class="ms-1 d-none d-sm-inline">{{'DDS Meeting' | translate}}</span>
          </span>
        </a>
      </li>
      }@if( code === 'CID_HOME') {
      <li class="nav-multi-item nav-item">
        <a href="#submenu1" data-bs-toggle="collapse" [attr.id]="'CID-routes-' + $index"
          [ngClass]="activeParentId === 'CIDRoutesMenu'? 'nav-link align-middle multi-level-dropdown active': 'nav-link align-middle multi-level-dropdown'"
          id="CIDRoutesMenu">
          <span class="icon-text-wrapper">
            <i class="bi bi-card-checklist"></i>
            <span class="ms-1 d-none d-sm-inline">{{'CID Routes' | translate}}</span>
          </span>
        </a>

        <ul class="collapse nav flex-column" id="submenu1" data-bs-parent="#menu">
          @if(showTaskLibrary){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'showTaskLibrary-' + $index" [routerLink]="'/landing-page/task-library'" [routerLinkActive]="'active'"
              (click)="setParentActive('CIDRoutesMenu')">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.library_of_task.header" | translate}}</span>
            </a>
          </li>
          } @if(showRouteLibrary){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'showRouteLibrary-' + $index" [routerLink]="'/landing-page/library-routes'"
              [routerLinkActive]="'active'">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.library_of_route.header" | translate}}</span>
            </a>
          </li>
          }@if(showRoutePreview){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'showRoutePreview-' + $index" [routerLink]="'/landing-page/route-preview'" [routerLinkActive]="'active'"
              (click)="setParentActive('CIDRoutesMenu')">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.route_preview.header" | translate}}</span>
            </a>
          </li>
          }
          @if(showCurrentRoute){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'showCurrentRoute-' + $index" [routerLink]="'/landing-page/current-routes'" [routerLinkActive]="'active'"
              (click)="setParentActive('CIDRoutesMenu')">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.current_route.header" | translate}}
                </span>
            </a>
          </li>
          }
          @if(showRCStatus){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'showRCStatus-' + $index" [routerLink]="'/landing-page/route-completion-status'"
              [routerLinkActive]="'active'" (click)="setParentActive('CIDRoutesMenu')">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.common.route_completion_status" | translate}}</span>
            </a>
          </li>
          }
          @if(showCommentSum){
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'show-comment-sum-' + $index" [routerLink]="'/landing-page/summary-view'" [routerLinkActive]="'active'"
              (click)="setParentActive('CIDRoutesMenu')">
              <span class="d-none d-sm-inline no-icon">{{"app.dcid.common.comment_summary" | translate}}</span>
            </a>
          </li>
          }
        </ul>
      </li>
      } @if (code === 'DDH_DEFCT_SOR') {
      <li class="nav-item">
        <a class="nav-link align-middle" [attr.id]="'defect-log-' + $index" [routerLinkActive]="'active'" [routerLink]="'/landing-page/defect-log'">
          <span class="icon-text-wrapper">
            <i class="bi bi-exclamation-triangle"></i>
            <span class="ms-1 d-none d-sm-inline">{{'Defect Log' | translate}}</span>
          </span>
        </a>
      </li>
      }@if( code === 'DCL_PRODSHDL') {
        <li class="nav-multi-item nav-item">
          <a href="#submenuSchedule" [attr.id]="'schedules-' + $index" data-bs-toggle="collapse"
            [ngClass]="activeParentId ==='scheduleMenu'? 'nav-link align-middle multi-level-dropdown active': 'nav-link align-middle multi-level-dropdown'"
            id="scheduleMenu">
            <span class="icon-text-wrapper">
              <img src="../../../../../assets/images/schedule-menu.svg" alt="schedule-menu-icon" />
            <span class="ms-1 d-none d-sm-inline">{{'Schedules' | translate}}</span>
            </span>
          </a>
  
          <ul class="collapse nav flex-column" id="submenuSchedule" data-bs-parent="#menu">
            <li class="nav-item" (click)="setParentActive('scheduleMenu')">
              <a class="nav-link align-middle" [attr.id]="'live_schedule-' + $index" [routerLink]="'/landing-page/live-schedule'"
                [routerLinkActive]="'active'" >
                <span class="d-none d-sm-inline no-icon">{{'Live Schedule' | translate}}</span>
              </a>
            </li>
            @for(prevCode of privilegeCode; track $index) { 
              @if(prevCode === 'DCL_BINRAW'){
                <li class="nav-item" (click)="setParentActive('scheduleMenu')">
                  <a class="nav-link align-middle" [attr.id]="'bin-rawattributes-' + $index" [routerLink]="'/landing-page/bin-raw-attribute'"
                    [routerLinkActive]="'active'" >
                    <span class="d-none d-sm-inline no-icon"> {{'Bin/Raw Attributes' | translate}}</span>
                  </a>
                </li>
              }
            }
            <li class="nav-item" (click)="setParentActive('scheduleMenu')">
              <a class="nav-link align-middle" [attr.id]="'production_schedule-' + $index" [routerLink]="'/landing-page/production-scheduled'"
                [routerLinkActive]="'active'">
                <span class="d-none d-sm-inline no-icon">{{'Production Schedule' | translate}}</span>
              </a>
            </li>
          </ul>
        </li>
      }@if( code === 'DCL_RECPCTLG_PREV') {
      <li class="nav-multi-item nav-item">
        <a href="#submenu2" data-bs-toggle="collapse"
          [ngClass]="activeParentId === 'centerlineMenu'? 'nav-link align-middle multi-level-dropdown active': 'nav-link align-middle multi-level-dropdown'"
          id="centerlineMenu">
          <span class="icon-text-wrapper">
            <i class="bi bi-eye-fill"></i>
            <span class="ms-1 d-none d-sm-inline">{{'Centerline' | translate}}</span>
          </span>
        </a>

        <ul class="collapse nav flex-column" id="submenu2" data-bs-parent="#menu">
          <li class="nav-item">
            <a class="nav-link align-middle" [attr.id]="'overview-' + $index" [routerLink]="'/landing-page/centerline-overview'"
              [routerLinkActive]="'active'" (click)="setParentActive('centerlineMenu')">
              <span class="d-none d-sm-inline no-icon">{{'Overview' | translate}}</span>
            </a>
          </li>
          @for(prevCode of privilegeCode; track $index) { 
            @if(prevCode === 'DCL_DEVNHSTR'){
            <li class="nav-item">
              <a [attr.id]="'deviation-link-' + prevCode + '-' + $index" class="nav-link align-middle" [routerLink]="'/landing-page/deviation-history'"
                [routerLinkActive]="'active'" (click)="setParentActive('centerlineMenu')">
                <span class="d-none d-sm-inline no-icon"> {{'Deviations' | translate}}<span
                    class="badge badge-pill badge-danger" [ngClass]="
                    deviationCount && deviationCount.toString().length > 4
                      ? 'new-width badge badge-pill badge-danger'
                      : 'badge badge-pill badge-danger'
                  ">{{deviationCount}}</span></span>
              </a>
            </li>
            }
          }
          <li class="nav-item">
            <a class="nav-link align-middle" [routerLink]="'/landing-page/recipe-catalogue'"
              [routerLinkActive]="'active'" [attr.id]="'recipe-atalogue-' + $index" (click)="setParentActive('centerlineMenu')">
              <span class="d-none d-sm-inline no-icon">{{'Recipe Catalogue' | translate}}</span>
            </a>
          </li>
        </ul>
      </li>
      }
      @if( code === 'DDS_SETTNG') {
      <li class="nav-item route-margin-cid">
        <a class="nav-link align-middle" [attr.id]="'settings-' + $index" [routerLink]="'/landing-page/settings'" [routerLinkActive]="'active'">
          <span class="icon-text-wrapper">
            <i class="bi bi-gear"></i><span class="ms-1 d-none d-sm-inline"> {{'Settings' | translate}}</span>
          </span>
        </a>
      </li>
      } }
    </ul>
  </div>

  <div class="bottom-section">
    <button class="btn btn-primary d-flex align-items-center" id="logout_btn" (click)="onLogout(true)">
      <i class="bi bi-box-arrow-right"></i><span>{{'Logout' | translate}}</span>
    </button>
  </div>
</div>