<div id="CID-cat" class="handoff-container category-container">
    <div class="row spacing-pt-10">
      <div id="CID-row-1" class="col"> 
                    <div id="CID-data-status" class="row {{CIDdata == true ? 'shiftly-status' :'shiftly-gray-status'}} m-0 mb-2 ">
                        <label id="CID-hd" class="safety-header report-header col-10 d-flex align-items-center">{{ "app.previous_hoff.CID" | translate }}</label>
                
                        <div id="CID-icon" class=" col d-flex justify-content-end align-items-center status-icon rounded-0 ">
                            <span id="CID-checked-status" class="d-flex align-items-center justify-content-center success-bg">
                             <i id="CID-dt-icon" class="bi {{CIDdata == true ? 'bi-check-lg icon-size' : 'bi-x-lg x-icon-size'}}"></i>
                            </span>
                        </div>  
                    </div>  
      </div> 
  </div> 
  </div>