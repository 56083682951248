import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  APP_INITIALIZER
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { authInterceptor } from './core/interceptors/authInterceptor';
import { GlobalErrorHandler } from './core/errors/globalErrorHandler';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  MsalGuard,
  MsalInterceptor,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  MsalGuardConfigurationFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './core/azureAD/auth-config';
import { DatePipe } from '@angular/common';
import { provideApplicationInsights } from './appInsight.provider';
import { faroInitializer } from './faro-initializer';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

export const appConfig: ApplicationConfig = {
  providers: [
    MsalService,
    MsalBroadcastService,
    MsalGuard,
    DatePipe,
    provideRouter(routes),
    provideApplicationInsights(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: authInterceptor,
      multi: true,
    },
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideAnimationsAsync(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigurationFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    // Faro Monitoring Initialization
    {
      provide: APP_INITIALIZER,
      useFactory: faroInitializer,
      deps: [], // Add dependencies if needed
      multi: true,
    },
  ],
};
