import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReportedDefectUiComponent } from '../../../reported-defect-ui/reported-defect-ui.component';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { DatetimeFormatPipe } from '../../../../../../../../../../shared/pipes/datetime-format.pipe';


@Component({
  selector: 'app-environment-previous',
  standalone: true,
  imports: [CommonModule,ReportedDefectUiComponent, TranslateModule,DatetimeFormatPipe],
  templateUrl: './environment-previous.component.html',
  styleUrl: './environment-previous.component.scss'
})
export class EnvironmentPreviousComponent {
  @Input() envResponse: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() reportedDefectArray:any=[];
  public reportedCategoryDefectArray=[];
  public moment: any = moment;
  @Input() selectedPlantCode: any;
  @Input() positionCode: any;

  ngOnChanges() { console.log("this.envResponse--",this.envResponse)
    if (this.reportedDefectArray && this.reportedDefectArray.length > 0) {
     this.reportedCategoryDefectArray = this.reportedDefectArray.filter((eachItem: any) =>  eachItem.impact?.code == 'ENV');
   }
 }

 getDateFromEpoch(epochtime: any) {
  if (epochtime) {
    return moment(parseInt(epochtime)).format('MM/DD/YYYY HH:mm');
  }
  else {
    return '';
  }
}

}
