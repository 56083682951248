import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { DatetimeFormatPipe } from '../../../../../../../../../../shared/pipes/datetime-format.pipe';

@Component({
  selector: 'app-delivery-previous',
  standalone: true,
  imports: [CommonModule, TranslateModule,DatetimeFormatPipe],
  templateUrl: './delivery-previous.component.html',
  styleUrl: './delivery-previous.component.scss'
})
export class DeliveryPreviousComponent {
  @Input() deliveryResponse: any;
  @Input() roleName: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() positionCode: any; 
  @Input() selectedPlantCode: any;
  public moment: any = moment;


  constructor(private datePipe: DatePipe) {}

  public timeFormat(response: any) {
    let ctrlData = [];
    for(let i=0; i < response.length; i++) {
      for(let j=0; j < response.length; j++) {
        ctrlData.push(response[i][j]);
        for(let k =0; k < Object.keys(response[i][j]).length ;k++ ){
          

        }
        
      }
    }
  }

  public getTime(response:any) {
    if(response) {
      const timeString = response;
      const date = new Date();
      const [hours, minutes] = timeString.split(':');
      date.setHours(Number(hours), Number(minutes));
      return this.datePipe.transform(date, 'shortTime');
    } else {
      return ' ';
    }
    
  }

}
