import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-other-issues-previous',
  standalone: true,
  imports: [CommonModule,TranslateModule],
  templateUrl: './other-issues-previous.component.html',
  styleUrl: './other-issues-previous.component.scss'
})
export class OtherIssuesPreviousComponent implements  OnChanges {
  @Input() otherIssueResponse: any;
  @Input() roleName: any;
  @Input() isSupervisorPosition: any;
  @Input() isOperatorPosition: any;
  @Input() positionCode: any;
  public isDateType?: boolean;
  public controlName?: string;
  public moment: any = moment;
  constructor(private datePipe: DatePipe) {}



  ngOnChanges() {
    this.filterLabel(this.otherIssueResponse);
  }

  public filterLabel(qualityResponse: any) {
    let data = [];
    for (let i = 0; i < qualityResponse.length; i++) {
      for (let j = 0; j < qualityResponse[i].length; j++) {
        data.push(qualityResponse[i][j]);
      }
    }

    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[i].ctrltype === 'date') {
        this.isDateType = true;
        if (data[i].controlName === 'last-fr-inspect-date') {
          this.controlName = 'last-fr-inspect-date';

        }
      }
    }


  }
  public getTime(response: any) {
    if (response) {
      const timeString = response;
      const date = new Date();
      const [hours, minutes] = timeString.split(':');
      date.setHours(Number(hours), Number(minutes));
      return this.datePipe.transform(date, 'shortTime');
    } else {
      return ' ';
    }

  }
}

