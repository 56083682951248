import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { ErrorHandler } from '@angular/core';
import { ApplicationInsightsService } from './appInsight.service';

export const provideApplicationInsights = () => {
  return [
    ApplicationInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
  ];
};