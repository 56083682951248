import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  DuplicateSkuPayload,
  IEditRecipeMeasurementRequest,
  IRecipeCreateMeasurementRequest,
  IRecipeResponse,
  IRecipeSensorsListResponse,
  ISensorThresholdsForRecipe,
  sensorDuplicateData,
} from '../DCL/components/models/recipe';
import { Injectable, inject } from '@angular/core';
import * as APP_CONST from '../../../app/constants/app.constants';
import { getHttpHeaders } from '../../http/header-config';
import { UserDetails } from '../../core/models/user-details';
import {
  ICreateMeasurementRequest,
  IEditMeasurementRequest,
  IPackagingSensorsListResponse,
  IPackingResponse,
  ISensorThresholdsForPackaging,
} from '../DCL/components/models/packaging';
import { IGetLinesResponse } from '../DCL/components/models/catalogue';
import { getHttpBodyData } from '../../http/body-config';
import {
  IDeviationDataCountResponse,
  IDeviationHistory,
  IEquipmentDetailsForOverview,
  IGetDeviationHistory,
  IGetDeviationHistoryRequest,
  IReportDeviationCauseResponse,
  IReportDeviationRequestBody,
  IReportDeviationResponse,
} from '../DCL/components/models/deviationHistory';
import { ConditionalExpr } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class DclApiService {
  // API endpoint
  private http = inject(HttpClient);
  isDuplicateClick: boolean = false;
  skuCodeData: any;
  recipeCodeData: any;
  duplicateSkuCodeAndSkuDescription: any;

  constructor() {}

  GetRecipeCatalogueDetails(
    userDetails: UserDetails,
    pageNumber: number,
    pageSize: number,
    searchText?: string,
    lineCode?: string
  ): Observable<any> {
    const encodedSearchText = searchText ? encodeURIComponent(searchText) : ''; //encoded the param value to avoid skipping string part after special character like  '&'
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetSkus?pageNumber=${pageNumber}&pageSize=${pageSize}&plantId=${userDetails.plantId}&searchText=${encodedSearchText}&lineCode=${lineCode}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getProductSKUDetails(
    userDetails: UserDetails,
    pageNumber: number,
    pageSize: number,
    skuCode: string
  ): Observable<any> {
    const encodedSkuCode = encodeURIComponent(skuCode);
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetRecipesForSku?pageNumber=${pageNumber}&pageSize=${pageSize}&skuCode=${encodedSkuCode}&plantId=${userDetails.plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  GetProductionScheduleDetails(
    userDetails: UserDetails,
    pageNumber: number,
    pageSize: number,
    plantId: string,
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/schedule/GetProductionScheduleDetails?startDate=${startDate}&endDate=${endDate}&pageNumber=${pageNumber}&pageSize=${pageSize}&plantId=${plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  GetProcessingDataDetails(
    userDetails: UserDetails,
    plantId: string
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/schedule/GetLiveScheduleForProcessing?plantId=${plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  GetPackagingDataDetails(
    userDetails: UserDetails,
    plantId: string
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/schedule/GetLiveScheduleForPackaging?plantId=${plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  GetBinRawDataDetails(
    userDetails: UserDetails,
    plantId: string
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/Schedule/GetBinrawData?plantId=${plantId}&languageCode=${userDetails.languageCode}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  getAssetsAndEquipmentsOfRecipe(
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetSensorThresholdsForAssetAsync?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getRecipeDetails(
    userDetails: UserDetails,
    pageNumber: number,
    pageSize: number,
    recipeCode: string,
    assetName: string
  ): Observable<ISensorThresholdsForRecipe> {
    const encodedAssetNameParam = encodeURIComponent(assetName); //encoded the param value to avoid skipping string part after '&'
    return this.http.get<ISensorThresholdsForRecipe>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetSensorThresholds?pageNumber=${pageNumber}&pageSize=${pageSize}&recipeCode=${recipeCode}&assetCategory=${encodedAssetNameParam}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  duplicateSkuDetails(
    userDetails: UserDetails,
    payload: DuplicateSkuPayload
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/dcl/api/Sku/CreateNewSkuFromRecipe`,
      payload,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  deleteRecipeCatalogueDetails(
    userDetails: UserDetails,
    skuCode: string,
  ): Observable<any> {
    const encodedSkuCode = encodeURIComponent(skuCode);
    return this.http.post(
      `${APP_CONST.API_URL}/dcl/api/Recipe/DeleteSku?skuCode=${encodedSkuCode}&plantId=${userDetails.plantId}`,
      getHttpBodyData({}),
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  setDuplicateClickValue(isDuplicateClick: boolean) {
    this.isDuplicateClick = isDuplicateClick;
  }

  getDuplicateClickValue() {
    return this.isDuplicateClick;
  }

  setSkuCodeValue(skuCodeData: any) {
    this.skuCodeData = skuCodeData;
  }

  getSkuCodeValue() {
    return this.skuCodeData;
  }

  setRecipeCodeValue(RecipeCodeData: any) {
    this.recipeCodeData = RecipeCodeData;
  }

  getRecipeCodeValue() {
    return this.recipeCodeData;
  }

  setDuplicateSkuCodeAndSkuDescription(duplicateSkuCodeAndSkuDescription: any) {
    this.duplicateSkuCodeAndSkuDescription = duplicateSkuCodeAndSkuDescription;
  }

  getDuplicateSkuCodeAndSkuDescription() {
    return this.duplicateSkuCodeAndSkuDescription;
  }

  duplicateRecipe(
    userDetails: UserDetails,
    data: sensorDuplicateData
  ): Observable<any> {
    console.log(
      'Duplicate Recipe ',
      userDetails.languageCode,
      userDetails.loginName
    );
    return this.http.post(
      `${APP_CONST.API_URL}/dcl/api/Recipe/DuplicateRecipe`,
      data,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  editRecipeThresholdDetails(
    userDetails: { languageCode: string; loginName: string },
    requestBody: IEditRecipeMeasurementRequest
  ): Observable<IRecipeResponse> {
    return this.http.put<IRecipeResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/EditRecipeThresholds`,
      requestBody,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getPackagingSensorThresholds(
    userDetails: UserDetails,
    pageNumber: number,
    pageSize: number,
    skuCode: string
  ): Observable<ISensorThresholdsForPackaging> {
    return this.http.get<ISensorThresholdsForPackaging>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetSensorThresholdsForPackaging?pageNumber=${pageNumber}&pageSize=${pageSize}&plantId=${userDetails.plantId}&SkuName=${skuCode}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getPackagingSensorsList(
    userDetails: UserDetails
  ): Observable<IPackagingSensorsListResponse> {
    return this.http.get<IPackagingSensorsListResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetPackagingSensors?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  CreateRecipeThresholdsForPackaging(
    userDetails: { languageCode: string; loginName: string },
    requestBody: ICreateMeasurementRequest
  ): Observable<IPackingResponse> {
    return this.http.post<IPackingResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/CreateRecipeThresholdsForPackaging`,
      requestBody,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  updatePackagingSensorThresholds(
    userDetails: { languageCode: string; loginName: string },
    requestBody: IEditMeasurementRequest
  ): Observable<IPackingResponse> {
    return this.http.put<IPackingResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/EditThresholdsForPackaging`,
      requestBody,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getRecipeCatalogueLines(
    userDetails: UserDetails,
    plantName: string
  ): Observable<IGetLinesResponse> {
    return this.http.get<IGetLinesResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetLines?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  // Delete Recipe
  deleteRecipe(
    userDetails: UserDetails,
    skuCode: string,
    recipeCode: string
  ): Observable<any> {
    const encodedSkuCode = encodeURIComponent(skuCode);
    return this.http.post(
      `${APP_CONST.API_URL}/dcl/api/Recipe/DeleteRecipeById?skuCode=${encodedSkuCode}&recipeId=${recipeCode}&plantId=${userDetails.plantId}`,
      getHttpBodyData({}),
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getSensorsForAsset(
    userDetails: UserDetails,
    assetName: string
  ): Observable<IRecipeSensorsListResponse> {
    const encodedAssetNameParam = encodeURIComponent(assetName); //encoded the param value to avoid skipping string part after '&'
    return this.http.get<IRecipeSensorsListResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/GetSensorsForAsset?assetCategory=${encodedAssetNameParam}&plantId=${userDetails.plantId}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  CreateRecipeThresholdsForProcessing(
    userDetails: { languageCode: string; loginName: string },
    requestBody: IRecipeCreateMeasurementRequest
  ): Observable<IRecipeResponse> {
    return this.http.post<IRecipeResponse>(
      `${APP_CONST.API_URL}/dcl/api/Recipe/CreateRecipeThresholdsForProcessing`,
      requestBody,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getDeviationHistoryData(
    userDetails: UserDetails,
    continuationToken: string,
    pageSize: number,
    plantId: string,
    positions: Array<string>,
    time: string
  ): Observable<IGetDeviationHistory> {
    const requestPayload: IGetDeviationHistoryRequest = {
      pageSize,
      plantId: plantId?.toString(),
      positionId: positions,
      time,
      userId: userDetails.userId?.toString(),
      languageCode: userDetails.languageCode,
    };
   return this.http.post<IGetDeviationHistory>(
      `${APP_CONST.API_URL}/dcl/api/Deviation/GetSensorDeviations`,
      continuationToken === '' ? requestPayload : {...requestPayload, continuationToken},
      getHttpHeaders({
        LoginName: userDetails.loginName,
      })
    );
  }

  getDeviationHistoryDataAsset(
    userDetails: UserDetails,
    continuationToken: string,
    pageSize: number,
    plantId: string,
    positions: Array<string>,
    assetName:string,
    lineId: string,
    plantCode: string
  ): Observable<IGetDeviationHistory> {
    const encodedAssetNameParam = encodeURIComponent(assetName); 
    const requestBody = {
      pageSize,
      plantId: plantId?.toString(),
      plantCode,
      positions,
      EquipmentCategory: encodedAssetNameParam,
      lineId,
      userId: userDetails.userId?.toString(),
      languageCode: userDetails.languageCode,
    };
     return this.http.post<IGetDeviationHistory>(
      `${APP_CONST.API_URL}/dcl/api/Deviation/GetSensorDeviationByAsset`,
      continuationToken === '' ? requestBody : {...requestBody, continuationToken },
      getHttpHeaders({
        LoginName: userDetails.loginName,
      })
    );
  }

  getDeviationDataCount(
    userDetails: UserDetails,
    plantId: string | number,
    plantName: string,
    position: Array<string>
  ): Observable<IDeviationDataCountResponse> {
    return this.http.get<IDeviationDataCountResponse>(
      `${
        APP_CONST.API_URL
      }/dcl/api/Deviation/ReportDeviationCount?plantId=${plantId}&positionId=${position}&userId=${userDetails.userId?.toString()}&LanguageCode=${
        userDetails.languageCode
      }`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }

  getReportDeviationCause(
    userDetails: any
  ): Observable<IReportDeviationCauseResponse> {
    return this.http.get<IReportDeviationCauseResponse>(
      `${APP_CONST.API_URL}/dcl/api/Deviation/GetDeviationsCauses?languageCode=${userDetails.result.
        preLangCode
        }`,
      getHttpHeaders({
        LoginName: userDetails.result.loginName,
      })
    );
  }

  ReportDeviation(
    userDetails: { languageCode: string; loginName: string },
    requestBody: IReportDeviationRequestBody,
    isJustified: 'true' | 'false',
    isReported: 'true' | 'false',
    reason: string,
    deviationCause: string,
    actionFixes: string,
    other: string,
    ReportDeviation:string
  ): Observable<IReportDeviationResponse> {
    const encodedReasonParam = encodeURIComponent(reason);
    const encodedDeviationCauseParam = encodeURIComponent(deviationCause);
    const encodedActionFixesParam = encodeURIComponent(actionFixes);

    return this.http.post<IReportDeviationResponse>(
      `${APP_CONST.API_URL}/dcl/api/Deviation/ReportDeviation?isJustified=${isJustified}&isReported=${isReported}&reason=${encodedReasonParam}&deviationCause=${encodedDeviationCauseParam}&actionFixes=${encodedActionFixesParam}&other=${other}&languageCode=${userDetails.languageCode}`,
      requestBody,
      getHttpHeaders({
        LoginName: userDetails.loginName,
      })
    );
  }

  // Center line Over view
  GetEquipmentDetailsForOverview(
    userDetails: UserDetails,
    positionId: string
  ): Observable<IEquipmentDetailsForOverview> {
    return this.http.get<IEquipmentDetailsForOverview>(
      `${APP_CONST.API_URL}/dcl/api/Overview/GetEquipmentDetailsForOverview?positionId=${positionId}&userId=${userDetails.userId?.toString()}&plantId=${userDetails.plantId?.toString()}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
  // Center line Over view
  getSensorDetailsForOverview(
    userDetails: UserDetails,
    equipmentCategory: string,
    lineId: string,
    pageNumber: number,
    pageSize: number,
    skuCode:string,
    bin:string,
  ): Observable<any> {
    const equipment = encodeURIComponent(equipmentCategory);
    return this.http.get(
      `${APP_CONST.API_URL}/dcl/api/Overview/GetSensorDetailsForOverview?equipmentCategory=${equipment}&plantId=${userDetails?.plantId}&lineId=${lineId}&latestSku=${skuCode}&bin=${bin}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      getHttpHeaders({
        LanguageCode: userDetails.languageCode,
        LoginName: userDetails.loginName,
      })
    );
  }
}
