import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';


@Component({
  selector: 'app-reported-defect-view',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './reported-defect-ui.component.html',
  styleUrl: './reported-defect-ui.component.scss'
})
export class ReportedDefectUiComponent  {
  @Input() responseData: any;
  @Input() reportedCategoryDefectArray: any;
  @Input() selectedPlantCode: any;
  @Input() tableId: string;
  
  getDateFromEpoch(epochtime: any) {
    if (epochtime) {
      return moment(parseInt(epochtime)).format('MM/DD/YYYY HH:mm');
    }
    else {
      return '';
    }

  }
}