export function getPlantListFromUserDetails(userDetailsData: any) {
  if (
    userDetailsData &&
    userDetailsData.result &&
    userDetailsData.result.plants.length > 0
  ) {
    let plantList = [];
    for (let item of userDetailsData.result.plants) {
      plantList.push({
        id: item.code,
        plantId: item.id,
        value: item.code,
        name: item.name,
        text: item.name,
      });
    }
    return plantList;
  } else {
    return [];
  }
}
