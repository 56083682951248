import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ShiftPosition } from '../models/shift-position';
import { UserDetails } from '../models/user-details';
import { Plant } from '../models/plant';
import { Line } from '../models/line';
import { LandingScreen } from '../models/landingScreen';
import { Language } from '../models/language';
import { UserSettings } from '../models/user-settings';
import { PreviousHandoff } from '../models/previous-handoff';
import { MdiPclReports } from '../models/mdi-pcl-reports';

const MOCK_BASE_URL = environment.mockApiUrl;
@Injectable({
  providedIn: 'root',
})
export class MockApiService {
  private http: HttpClient = inject(HttpClient);

  constructor() { }

  getData(): Observable<ShiftPosition[]> {
    return this.http.get<ShiftPosition[]>(`${MOCK_BASE_URL}/shiftPosition`);
  }

  getUserData(): Observable<UserDetails[]> {
    return this.http.get<UserDetails[]>(`${MOCK_BASE_URL}/userDetails`);
  }

  getLanguages(): Observable<Language[]> {
    return this.http.get<Language[]>(`${MOCK_BASE_URL}/language`);
  }

  getPlantList(): Observable<Plant[]> {
    return this.http.get<Plant[]>(`${MOCK_BASE_URL}/plant`);
  }
  getLineList(): Observable<Line[]> {
    return this.http.get<Line[]>(`${MOCK_BASE_URL}/line`);
  }
  getLandingScreenList(): Observable<LandingScreen[]> {
    return this.http.get<LandingScreen[]>(`${MOCK_BASE_URL}/landingScreen`);
  }
  // Fetch data based on ID
  getDataByKey(id: number): Observable<ShiftPosition> {
    return this.http.get<ShiftPosition>(`${MOCK_BASE_URL}/${id}`);
  }

  createPosition(position: ShiftPosition) {
    return this.http.post<ShiftPosition>(MOCK_BASE_URL, position);
  }

  updatePosition(id: number, name: string): Observable<ShiftPosition> {
    return this.http.put<ShiftPosition>(`${MOCK_BASE_URL}/${id}`, name);
  }

  delete(id: string) {
    return this.http.delete<any>(`${MOCK_BASE_URL}/${id}`);
  }
  //Previous Handoff Detailed Page
  getDetailPH() {
    return this.http.get<PreviousHandoff[]>(`${MOCK_BASE_URL}/prevhandoff`);
  }
  //Cuttent Handoff Detailed Page
  getDetailCurrentHandOff() {
    return this.http.get<PreviousHandoff[]>(`${MOCK_BASE_URL}/curnthandoff`);
  }

  saveShiftPosition(position: ShiftPosition[]) {
    console.log('selected shift position', position);
    return this.http.post<ShiftPosition[]>(`${MOCK_BASE_URL}/shiftPosition`, position);
  }

  saveUserData(userSettings: UserSettings[]) {
    console.log('user settings', userSettings);
    return this.http.post<UserSettings[]>(`${MOCK_BASE_URL}/userSettings`, userSettings);
  }

  savePlantData(plant: Plant[]) {
    return this.http.post<Plant[]>(`${MOCK_BASE_URL}/plant`, plant);
  }

  saveLanguageData(language: Language[]) {
    return this.http.post<Language[]>(`${MOCK_BASE_URL}/language`, language);
  }

  getReportsShift() {
    return this.http.get<MdiPclReports[]>(`${MOCK_BASE_URL}/mdiPclReports`);
  }
}
