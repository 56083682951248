import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CurrentHandoff } from '../models/current-handoff';


const BASE_URL = 'https://jsonplaceholder.typicode.com/';
const API_URL = environment.apiUrl;
const API_KEY = environment.apiKey;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // API endpoint
  private http = inject(HttpClient);
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor() { }

  ///// get data from the API
  getData(): Observable<any> {
    return this.http.get(`${BASE_URL}/posts?_limit=10`);
  }

  ///// get data based on API key[GET]
  getcurrentHandoff(): Observable<CurrentHandoff> {
    return this.http.get<CurrentHandoff>(`${API_URL}?api_key=${API_KEY}`); 
  }

  ///// get data based on id
  getcurrentHandoffById(id: number): Observable<CurrentHandoff> {
    return this.http.get<CurrentHandoff>(`${BASE_URL}/posts?_limit=10/${id}`); 
  }

  ///// Send data [POST]
  postCurrentHandoff(data: CurrentHandoff[]): Observable<CurrentHandoff> {
    return this.http.post<any>(`${BASE_URL}`, data);
  }

  ///// Update Data [PUT]
  updateCurrentHandoff(data: CurrentHandoff[]): Observable<void> {
    return this.http.put<void>(`${BASE_URL}`, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
  }
}
