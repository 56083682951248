import { Component, Input, NgModule } from '@angular/core';
import { SideNavComponent } from '../layout/side-nav/side-nav.component';
import { UserSideNavComponent } from './user-side-nav/user-side-nav.component';
import { CommonModule } from '@angular/common';
import * as APP_CONST from '../../../../app/constants/app.constants';

@Component({
  selector: 'app-user-settings',
  standalone: true,
  imports: [UserSideNavComponent, CommonModule],
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss'
})
export class UserSettingsComponent {
  public envCode = APP_CONST.ENVIRONMENT_CODE;
  public appVersion = APP_CONST.ENVIRONMENT_V;

  public envColor: string = '';
  @Input() userDetailsInfo: any;
  ngOnInit() {
    this.envColor = this.environmentColorCode();
  }
  environmentColorCode() {
    switch (APP_CONST.ENVIRONMENT_CODE) {
      case APP_CONST.ENVIRONMENT_PROD:
        return 'prod-env-class';
      case APP_CONST.ENVIRONMENT_QA:
        return 'qa-env-class';
      case APP_CONST.ENVIRONMENT_PRE_PROD:
        return 'pre-prod-env-class';
      case APP_CONST.ENVIRONMENT_DEV:
        return 'dev-env-class';
      default:
        return '';
    }
  }
}
