import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, inject } from '@angular/core';
import { SafetyPreviousComponent } from './components/safety-previous/safety-previous.component';
import { QualityPreviousComponent } from './components/quality-previous/quality-previous.component';
import { DeliveryPreviousComponent } from './components/delivery-previous/delivery-previous.component';
import { CostPreviousComponent } from './components/cost-previous/cost-previous.component';
import { EnvironmentPreviousComponent } from './components/environment-previous/environment-previous.component';
import { PeoplePreviousComponent } from './components/people-previous/people-previous.component';
import { CenterlineDeviationsComponent } from './components/centerline-deviations/centerline-deviations.component';
import { CidComponent } from './components/cid/cid.component';
import { EquipmentStopsComponent } from './components/equipment-stops/equipment-stops.component';
import { EquipmentDefectsComponent } from './components/equipment-defects/equipment-defects.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getUserDetails } from '../../../../../../../../helper/getUserDetails';
import { LanguageSelectionService } from '../../../../../../../../core/services/language-selection.service';
import { OtherIssuesPreviousComponent } from './components/other-issues-previous/other-issues-previous.component';
import moment from 'moment';
@Component({
  selector: 'app-detailed-view',
  standalone: true,
  imports: [
    CommonModule,
    OtherIssuesPreviousComponent,
    SafetyPreviousComponent,
    QualityPreviousComponent,
    DeliveryPreviousComponent,
    CostPreviousComponent,
    EnvironmentPreviousComponent,
    PeoplePreviousComponent,
    CenterlineDeviationsComponent,
    CidComponent,
    EquipmentStopsComponent,
    EquipmentDefectsComponent,
    TranslateModule,
  ],
  templateUrl: './detailed-view.component.html',
  styleUrl: './detailed-view.component.scss',
})
export class DetailedViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() responseData: any;
  @Input() tabSelected: any;
  @Input() roleName?: string;
  @Input() createdBy: any;
  @Input() templateDetails: any;
  @Input() isCIDCompleted: any;
  @Input() positionCode: any;
  @Input() selectedPlantCode: any;
  @Input() reportedDefectArray: any;
  @Input() reportedRTDuetDefectObj: any;
  @Input() reportedCLDeviationArray: any;
  private languageSelectionService = inject(LanguageSelectionService);
  public informationSetion: any;
  public informationData: any;
  public safetySection: any;
  public selectedLanguage: any;
  public userDetailsData: any;
  public isLangchange: any;
  public safetyTemplate: any = [];
  public safetyControls: any = [];
  public safetyResponse: any = [];
  public safetyFinalResponse: any = [];
  public CIDdata: any = false;
  public qualitySection: any;
  public qualityResponse: any = [];
  public qualityTemplate: any = [];
  public qualityControls: any = [];
  public qualityFinalResponse: any = [];

  public otherIssueSection: any;
  public otherIssueResponse: any = [];
  public otherIssueTemplate: any = [];
  public otherIssueControls: any = [];
  public otherIssueFinalResponse: any = [];

  public deliverySection: any;
  public deliveryResponse: any = [];
  public deliveryTemplate: any = [];
  public deliveryControls: any = [];
  public deliveryFinalResponse: any = [];

  public costResponse: any = [];
  public costTemplate: any = [];
  public costControls: any = [];
  public costFinalResponse: any = [];

  public peopleResponse: any = [];
  public peopleTemplate: any = [];
  public peopleControls: any = [];
  public peopleFinalResponse: any = [];

  public environmentResponse: any = [];
  public environmentTemplate: any = [];
  public environmentControls: any = [];
  public environmentFinalResponse: any = [];

  public centerlineResponse: any = [];
  public centerlineTemplate: any = [];
  public centerlineControls: any = [];
  public centerlineFinalResponse: any = [];

  public cidResponse: any = [];
  public cidTemplate: any = [];
  public cidControls: any = [];
  public cidFinalResponse: any = [];

  public equipmentDefectsResponse: any = [];
  public equipmentDefectsTemplate: any = [];
  public equipmentDefectsControls: any = [];
  public equipmentDefectsFinalResponse: any = [];

  public equipmentStopsResponse: any = [];
  public equipmentStopsTemplate: any = [];
  public equipmentStopsControls: any = [];
  public equipmentStopsFinalResponse: any = [];

  public isOperatorPosition?: boolean;
  public isSupervisorPosition?: boolean;

  public selectedPositionName?: string;
  public reportedDefectSafetyCount = 0;
  public reportedDefectQualityCount = 0;
  public reportedDefectEDCount = 0;
  public reportedDefectENVCount = 0;
  public userInfoDetails: any;

  public finalResponse: any = [];
  public categoryArr: any = [];
  public safetyRow: any = {
    type: 'SAFETY',
    controls: {
      safety_row1: [
        {
          labeltext: 'Items',
          ctrltype: 'label',
          controlname: 'safety-item',
        },
        {
          labeltext: 'Comments',
          ctrltype: 'label',
          controlname: 'safety-comment',
        },
      ],
      safety_row2: [
        {
          labeltext: 'Any risk or unsafe conditions?',
          ctrltype: 'rdbutton',
          controlname: 'safety-risk',
          options: ['Yes', 'No', 'NA'],
        },
        {
          ctrltype: 'textbox',
          controlname: 'safety-risk-cmt',
          placeholder: 'If yes, enter comment',
        },
      ],
      safety_row3: [
        {
          labeltext: 'Fryer Fire Risk',
          ctrltype: 'rdbutton',
          controlname: 'safety-fryer',
          options: ['Low', 'Medium', 'High'],
        },
        {
          ctrltype: 'textbox',
          controlname: 'safety-fryer-cmt',
          placeholder: 'Enter comment',
        },
      ],
      safety_row4: [
        {
          labeltext: 'Time of last Fryer Inspection',
          ctrltype: 'date',
          controlname: 'safety-fryer-inspection',
        },
        {
          ctrltype: 'textbox',
          controlname: 'safety-fryer-inspection-cmt',
          placeholder: 'Enter comment',
        },
      ],
      safety_row5: [
        {
          labeltext: 'Time until next Fryer Scrape',
          ctrltype: 'number',
          controlname: 'safety-fryer-scrape',
        },
        {
          ctrltype: 'textbox',
          controlname: 'safety-fryer-scrape-cmt',
          placeholder: 'Enter comment',
        },
      ],
      safety_row6: [
        {
          labeltext: 'Reported Safety Issues',
          ctrltype: 'label',
          type: 'defect',
        },
      ],
      safety_row7: [
        {
          labeltext: 'Any other safety observation or risk identified?',
          ctrltype: 'rdbutton',
          controlname: 'safety-other-risk',
          options: ['Yes', 'No'],
        },
        {
          labeltext: 'CLICK HERE TO REPORT',
          ctrltype: 'link',
        },
      ],
    },
  };

  ngOnInit() {
    if (this.templateDetails) {
      this.fetchCategoryDetails(this.templateDetails.dddshandofftemplate);
    }
    this.selectedPositionName = this.positionCode;
    this.setLanguage();
    this.checkOperatorPositions(this.positionCode);
    this.checkSupervisorPositions(this.positionCode);

    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
      this.userInfoDetails = getUserDetails(this.userDetailsData);
    }
  }
  ngAfterViewInit() {
    this.addUniqueIds();
  }
  setLanguage() {
    this.selectedLanguage = sessionStorage.getItem('selectedLanguage');
    if (sessionStorage.getItem('userDetails') !== null) {
      this.userDetailsData = JSON.parse(
        sessionStorage.getItem('userDetails') || ''
      );
    }
    if (this.selectedLanguage && this.selectedLanguage !== 'undefined') {
      if (
        this.selectedLanguage !==
        getUserDetails(this.userDetailsData).languageCode
      ) {
        this.isLangchange = true;
      }
      this.languageSelectionService.setLanguage(this.selectedLanguage);
    } else {
      if (this.userDetailsData && this.userDetailsData.result) {
        let langCode = this.userDetailsData.result.preLangCode;
        this.translateService.setDefaultLang(langCode);
      } else {
        this.translateService.setDefaultLang('en');
      }
    }
  }

  ngOnChanges() {
    for (let i = 0; i < this.responseData.length; i++) {
      if (this.responseData[i].type === 'INFORMATION') {
        this.informationData = this.responseData[i].controls;      
      }
    }
    this.selectedPositionName = this.positionCode;
    if (this.reportedDefectArray) {
      let reportdED;
      if (this.selectedPlantCode == 'FVL') {
        reportdED = this.reportedDefectArray.filter(
          (eachItem: any) =>
            eachItem.impact?.code == 'DEL' ||
            eachItem.impact?.code == 'CST' ||
            eachItem.impact?.code == 'PPL' ||
            eachItem.impact?.code == 'ENV'
        );
      } else if (this.selectedPlantCode != 'FVL') {
        reportdED = this.reportedDefectArray.filter(
          (eachItem: any) =>
            eachItem.impact?.code == 'DEL' ||
            eachItem.impact?.code == 'CST' ||
            eachItem.impact?.code == 'PPL'
        );
      }
      let reportdSFT = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'SFT'
      );
      let reportdQLT = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'QLT'
      );
      let reportdENV = this.reportedDefectArray.filter(
        (eachItem: any) => eachItem.impact?.code == 'ENV'
      );
      if (reportdED && reportdED.length > 0) {
        this.reportedDefectEDCount = reportdED.length;
      }
      if (reportdSFT && reportdSFT.length > 0) {
        this.reportedDefectSafetyCount = reportdSFT.length;
      }
      if (reportdQLT && reportdQLT.length > 0) {
        this.reportedDefectQualityCount = reportdQLT.length;
      }
      if (reportdENV && reportdENV.length > 0) {
        this.reportedDefectENVCount = reportdENV.length;
      }
    }
    if (this.isCIDCompleted && this.isCIDCompleted == true) {
      this.CIDdata = true;
    } else {
      this.CIDdata = false;
    }
  }

  constructor(private translateService: TranslateService, private el: ElementRef, private renderer: Renderer2) { }

  public checkOperatorPositions(positionCode: any): void {
    switch (positionCode) {
      case 'SPLS':
        this.isOperatorPosition = true;
        break;
      case 'SPLMIX':
        this.isOperatorPosition = true;
        break;
      case 'SPLFRY':
        this.isOperatorPosition = true;
        break;
      case 'PKG1':
        this.isOperatorPosition = true;
        break;
      case 'PKG2':
        this.isOperatorPosition = true;
        break;
      case 'PKG3':
        this.isOperatorPosition = true;
        break;
      case 'PKG4':
        this.isOperatorPosition = true;
        break;
      case 'PKG5':
        this.isOperatorPosition = true;
        break;
      case 'PKG6':
        this.isOperatorPosition = true;
        break;
      case 'PKG7':
        this.isOperatorPosition = true;
        break;
      case 'PKG8':
        this.isOperatorPosition = true;
        break;
      case 'PKG9':
        this.isOperatorPosition = true;
        break;
      case 'CDR':
        this.isOperatorPosition = true;
        break;
      case 'MR':
        this.isOperatorPosition = true;
        break;
      case 'PL':
        this.isOperatorPosition = true;
        break;
      case 'BTR':
        this.isOperatorPosition = true;
        break;
      case 'CR':
        this.isOperatorPosition = true;
        break;
      case 'SADR':
        this.isOperatorPosition = true;
        break;
      case 'CT':
        this.isOperatorPosition = true;
        break;
      case 'FRY':
        this.isOperatorPosition = true;
        break;
      case 'PH':
        this.isOperatorPosition = true;
        break;
      case 'PSL':
        this.isOperatorPosition = true;
        break;
      case 'SPDT':
        this.isOperatorPosition = true;
        break;
      case 'CF':
        this.isOperatorPosition = true;
        break;
      case 'EL':
        this.isOperatorPosition = true;
        break;
      case 'BMN':
        this.isOperatorPosition = true;
        break;
      case 'AFTR':
        this.isOperatorPosition = true;
        break;
      case 'CDR':
        this.isOperatorPosition = true;
        break;
      case 'FTRL1':
        this.isOperatorPosition = true;
        break;
      case 'FTRL2':
        this.isOperatorPosition = true;
        break;
      case 'CTXL1':
        this.isOperatorPosition = true;
        break;
      case 'CTXL2':
        this.isOperatorPosition = true;
        break;
      case 'CC':
        this.isOperatorPosition = true;
        break;
      case 'CM':
        this.isOperatorPosition = true;
        break;
      case 'NTYR':
        this.isOperatorPosition = true;
        break;
      case 'QC':
        this.isOperatorPosition = true;
        break;
      case 'PLR':
        this.isOperatorPosition = true;
        break;
      case 'FORK':
        this.isOperatorPosition = true;
        break;
      case 'PCMPN':
        this.isOperatorPosition = true;
        break;
      case 'BTR':
        this.isOperatorPosition = true;
        break;
      case 'CTR':
        this.isOperatorPosition = true;
        break;
      case 'STR':
        this.isOperatorPosition = true;
        break;
      case 'PSA':
        this.isOperatorPosition = true;
        break;
      case 'PKG41':
        this.isOperatorPosition = true;
        break;
      case 'PKG42':
        this.isOperatorPosition = true;
        break;
      case 'PKG43':
        this.isOperatorPosition = true;
        break;
      case 'PKG44':
        this.isOperatorPosition = true;
        break;
      case 'PKG45':
        this.isOperatorPosition = true;
        break;
      case 'PKG46':
        this.isOperatorPosition = true;
        break;
      case 'ATSPL':
        this.isOperatorPosition = true;
        break;
      case 'FROP':
        this.isOperatorPosition = true;
        break;
      case 'PLEBL1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM1L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM2L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM3L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM4L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM5L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM6L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM7L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM8L1':
        this.isOperatorPosition = true;
        break;
      case 'PLEBL2':
        this.isOperatorPosition = true;
        break;
      case 'TRPL1':
        this.isOperatorPosition = true;
        break;
      case 'TRPL2':
        this.isOperatorPosition = true;
        break;
      case 'PKGL1':
        this.isOperatorPosition = true;
        break;
      case 'PKGL2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM2L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM3L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM4L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM5L2':
        this.isOperatorPosition = true;
        break;
      case 'ENSM6L2':
        this.isOperatorPosition = true;
        break;
      case 'PLTRL1':
        this.isOperatorPosition = true;
        break;
      case 'PLTRL2':
        this.isOperatorPosition = true;
        break;
      case 'LPL1':
        this.isOperatorPosition = true;
        break;
      case 'LPL2':
        this.isOperatorPosition = true;
        break;
      case 'FRMS':
        this.isOperatorPosition = true;
        break;
      case 'ENSM2L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM3L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM4L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM5L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM6L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM7L1':
        this.isOperatorPosition = true;
        break;
      case 'ENSM8L1':
        this.isOperatorPosition = true;
        break;
      case 'PLEBL2':
        this.isOperatorPosition = true;
        break;
      case 'TRPL1':
        this.isOperatorPosition = true;
        break;
      case 'TRPL2':
        this.isOperatorPosition = true;
        break;
      case 'PKGL1':
        this.isOperatorPosition = true;
        break;
      case 'PKGL2':
        this.isOperatorPosition = true;
        break;
      case 'MLFR1':
        this.isOperatorPosition = true;
        break;
      case 'CTNRL1':
        this.isOperatorPosition = true;
        break;
      case 'CTNRL2':
        this.isOperatorPosition = true;
        break;
      case 'TOL1':
        this.isOperatorPosition = true;
        break;
      case 'TOL2':
        this.isOperatorPosition = true;
        break;
      case 'MLFR2':
        this.isOperatorPosition = true;
        break;
      case 'EBFLK':
        this.isOperatorPosition = true;
        break;
      case 'MLGFLK':
        this.isOperatorPosition = true;
        break;
      case 'NTRL1':
        this.isOperatorPosition = true;
        break;
      case 'NTRL2':
        this.isOperatorPosition = true;
        break;
      case 'DCIL1':
        this.isOperatorPosition = true;
        break;
      case 'EPRL1':
        this.isOperatorPosition = true;
        break;
      case 'EPRL2':
        this.isOperatorPosition = true;
        break;
        case 'DCIL2':
        this.isOperatorPosition = true;
        break;
      case 'CYLRL1':
      case 'CYLRL2':
        this.isOperatorPosition = true;
        break; 
      case 'CTOTL1':
          this.isOperatorPosition = true;
          break;
      case 'CTOTL2':
          this.isOperatorPosition = true;
          break;    
      default:
        break;
    }
  }

  public checkSupervisorPositions(positionCode: any): void {
    switch (positionCode) {
      case 'FLS':
        this.isSupervisorPosition = true;
        break;
      case 'SLS':
        this.isSupervisorPosition = true;
        break;
      case 'PLH':
        this.isSupervisorPosition = true;
        break;
      case 'PLS':
        this.isSupervisorPosition = true;
        break;
      case 'FLS1':
        this.isSupervisorPosition = true;
        break;
      case 'FLS2':
        this.isSupervisorPosition = true;
        break;
        case 'PLH1':
          this.isSupervisorPosition = true;
          break; 
          case 'PLH2':
          this.isSupervisorPosition = true;
          break; 
      default:
        break;
    }
  }

  fetchCategoryDetails(templateData: any) {
    let categories = templateData.categories;
    let safetyInfo;
    let qualityInfo;
    let otherIssueInfo;
    let deliveryInfo;
    let costInfo;
    let peopleInfo;
    let envInfo;
    let centerlineInfo;
    let cidInfo;
    let equDefectInfo;
    let equStopsInfo;
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].type === 'SAFETY') {
        safetyInfo = categories[i].controls;
        this.getSafetyData(safetyInfo);
      } else if (categories[i].type === 'QUALITY') {
        qualityInfo = categories[i].controls;
        this.getQualityData(qualityInfo);
      } else if (categories[i].type === 'OTHER ISSUES') {
        otherIssueInfo = categories[i].controls;
        this.getOtherIssueData(otherIssueInfo);
      } else if (categories[i].type === 'DELIVERY') {
        deliveryInfo = categories[i].controls;
        this.getDeliveryData(deliveryInfo);
      } else if (categories[i].type === 'COST') {
        costInfo = categories[i].controls;
        this.getCostData(costInfo);
      } else if (categories[i].type === 'PEOPLE') {
        peopleInfo = categories[i].controls;
        this.getPeopleData(peopleInfo);
      } else if (categories[i].type === 'ENVIRONMENT') {
        envInfo = categories[i].controls;
        this.getEnvironmentData(envInfo);
      } else if (categories[i].type === 'CENTERLINE DEVIATIONS') {
        centerlineInfo = categories[i].controls;
        this.getCenterlineData(centerlineInfo);
      } else if (categories[i].type === 'CID') {
        cidInfo = categories[i].controls;
        this.getCidData(cidInfo);
      } else if (categories[i].type === 'EQUIPMENT DEFECTS') {
        equDefectInfo = categories[i].controls;
        this.getEquDefectData(equDefectInfo);
      } else if (categories[i].type === 'EQUIPMENT STOPS') {
        equStopsInfo = categories[i].controls;
        this.getEquStopsData(equStopsInfo);
      }
    }
  }

  getSafetyData(safetyInfo: any) {
    this.safetyResponse = safetyInfo;
    for (let i = 0; i < Object.keys(this.safetyResponse).length; i++) {
      this.safetyTemplate.push(this.safetyResponse['safety-row' + (i + 1)]);
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'SAFETY') {
        this.safetyControls = this.responseData[j].controls;
      }
    }

    this.safetyFinalResponse = this.mappedData(
      this.safetyTemplate,
      this.safetyControls
    );
  }
  getQualityData(qualityInfo: any) {
    this.qualityResponse = qualityInfo;
    for (let i = 0; i < Object.keys(this.qualityResponse).length; i++) {
      this.qualityTemplate.push(this.qualityResponse['quality-row' + (i + 1)]);
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'QUALITY') {
        this.qualityControls = this.responseData[j].controls;
      }
    }

    this.qualityFinalResponse = this.mappedData(
      this.qualityTemplate,
      this.qualityControls
    );
  }

  getOtherIssueData(otherIssueInfo: any) {
    this.otherIssueResponse = otherIssueInfo;
    for (let i = 0; i < Object.keys(this.otherIssueResponse).length; i++) {
      this.otherIssueTemplate.push(
        this.otherIssueResponse['other-issues-row' + (i + 1)]
      );
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'OTHER ISSUES') {
        this.otherIssueControls = this.responseData[j].controls;
      }
    }

    this.otherIssueFinalResponse = this.mappedData(
      this.otherIssueTemplate,
      this.otherIssueControls
    );
  }

  getDeliveryData(deliveryInfo: any) {
    this.deliveryResponse = deliveryInfo;

    for (let i = 0; i < Object.keys(this.deliveryResponse).length; i++) {
      this.deliveryTemplate.push(
        this.deliveryResponse['delivery-row' + (i + 1)]
      );
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'DELIVERY') {
        this.deliveryControls = this.responseData[j].controls;
      }
    }

    this.deliveryFinalResponse = this.mappedData(
      this.deliveryTemplate,
      this.deliveryControls
    );
  }

  getCostData(costInfo: any) {
    this.costResponse = costInfo;
    for (let i = 0; i < Object.keys(this.costResponse).length; i++) {
      this.costTemplate.push(this.costResponse['cost-row' + (i + 1)]);
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'COST') {
        this.costControls = this.responseData[j].controls;
      }
    }

    this.costFinalResponse = this.mappedData(
      this.costTemplate,
      this.costControls
    );
  }

  getPeopleData(peopleInfo: any) {
    this.peopleResponse = peopleInfo;
    if (this.peopleResponse.length === 1) {
      this.peopleTemplate.push(this.peopleResponse[0]);
    } else {
      for (let i = 0; i < Object.keys(this.peopleResponse).length; i++) {
        this.peopleTemplate.push(this.peopleResponse['people-row' + (i + 1)]);
      }
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'PEOPLE') {
        this.peopleControls = this.responseData[j].controls;
      }
    }

    this.peopleFinalResponse = this.mappedData(
      this.peopleTemplate,
      this.peopleControls
    );
  }

  getEnvironmentData(envInfo: any) {
    this.environmentResponse = envInfo;

    for (let i = 0; i < Object.keys(this.environmentResponse).length; i++) {
      this.environmentTemplate.push(
        this.environmentResponse['environment-row' + (i + 1)]
      );
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'ENVIRONMENT') {
        this.environmentControls = this.responseData[j].controls;
      }
    }

    this.environmentFinalResponse = this.mappedData(
      this.environmentTemplate,
      this.environmentControls
    );
  }

  getCenterlineData(centerlineInfo: any) {
    this.centerlineResponse = centerlineInfo;

    for (let i = 0; i < Object.keys(this.centerlineResponse).length; i++) {
      this.centerlineTemplate.push(this.centerlineResponse['cd-row' + (i + 1)]);
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'CENTERLINE DEVIATIONS') {
        this.centerlineControls = this.responseData[j].controls;
      }
    }

    this.centerlineFinalResponse = this.mappedData(
      this.centerlineTemplate,
      this.centerlineControls
    );
  }

  getCidData(cidInfo: any) {
    if (cidInfo && cidInfo != undefined) {
      this.cidResponse = cidInfo;

      for (let i = 0; i < Object.keys(this.cidResponse).length; i++) {
        this.cidTemplate.push(this.cidResponse['cid-row' + (i + 1)]);
      }

      for (let j = 0; j < this.responseData.length; j++) {
        if (this.responseData[j].type === 'CID') {
          if (
            this.responseData[j].controls &&
            this.responseData[j].controls.length > 0
          ) {
          } else {
          }
        }
      }

      this.cidFinalResponse = this.mappedData(
        this.cidTemplate,
        this.cidControls
      );
    }
  }

  getEquDefectData(equDefectInfo: any) {
    this.equipmentDefectsResponse = equDefectInfo;

    for (
      let i = 0;
      i < Object.keys(this.equipmentDefectsResponse).length;
      i++
    ) {
      this.equipmentDefectsTemplate.push(
        this.equipmentDefectsResponse['ed-row' + (i + 1)]
      );
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'EQUIPMENT DEFECTS') {
        this.equipmentDefectsControls = this.responseData[j].controls;
      }
    }

    this.equipmentDefectsFinalResponse = this.mappedData(
      this.equipmentDefectsTemplate,
      this.equipmentDefectsControls
    );
  }

  getEquStopsData(equStopsInfo: any) {
    this.equipmentStopsResponse = equStopsInfo;
    if (this.equipmentStopsResponse) {
      for (
        let i = 0;
        i < Object.keys(this.equipmentStopsResponse).length;
        i++
      ) {
        this.equipmentStopsTemplate.push(
          this.equipmentStopsResponse['es-row' + (i + 1)]
        );
      }
    }

    for (let j = 0; j < this.responseData.length; j++) {
      if (this.responseData[j].type === 'EQUIPMENT STOPS') {
        this.equipmentStopsControls = this.responseData[j].controls;
      }
    }

    this.equipmentStopsFinalResponse = this.mappedData(
      this.equipmentStopsTemplate,
      this.equipmentStopsControls
    );
  }

  public mappedData(template: any, response: any): any {
    let templateData = template;
    let responseData = response;
    for (let i = 0; i < responseData.length; i++) {
      let controlname = responseData[i].controlname;
      for (let j = 0; j < templateData.length; j++) {
        for (let k = 0; k < templateData[j].length; k++) {
          if (templateData[j][k].controlname === controlname) {
            templateData[j][k].response = responseData[i].response;
          }
        }
      }
    }
    this.finalResponse = templateData;
    return this.finalResponse;
  }

  public mappedData1(template: any, response: any): any {
    let templateData = template;
    let responseData = response;
    for (let i = 0; i < responseData.length; i++) {
      let controlname = responseData[i].controlname;
      if (templateData.length === 1) {
        for (let j = 0; j < templateData.length; j++) {
          if (templateData[j].controlname === controlname) {
            templateData[j].response = responseData[i].response;
          }
        }
      } else {
        for (let j = 0; j < templateData.length; j++) {
          for (let k = 0; k < templateData[j].length; k++) {
            if (templateData[j][k].controlname === controlname) {
              templateData[j][k].response = responseData[i].response;
            }
          }
        }
      }
    }
    this.finalResponse = templateData;
    return this.finalResponse;
  }

  public getSummaryCountCLD() {
    if (
      this.reportedCLDeviationArray &&
      this.reportedCLDeviationArray?.length > 0
    ) {
      const centerlineControls = this.responseData.find(
        (item: any) => item.type === 'CENTERLINE DEVIATIONS'
      );
      let reportedCLDeviationArrayTemp = [];
      if (
        centerlineControls.controls &&
        centerlineControls.controls.length > 0
      ) {
        reportedCLDeviationArrayTemp = this.reportedCLDeviationArray.filter(
          (x: { reportId: any }) =>
            centerlineControls.controls.findIndex(
              (y: { cldRPTId: any }) => y.cldRPTId === x.reportId
            ) >= 0
        );
        this.reportedCLDeviationArray = reportedCLDeviationArrayTemp;
      }
      return this.reportedCLDeviationArray.length;
    } else {
      return 0;
    }
  }

  public getSummaryCount(itemType: string) {
    const safetyItem = this.responseData.find(
      (item: any) => item.type === itemType
    );
    if (itemType === 'EQUIPMENT STOPS') {
      if (
        this.reportedRTDuetDefectObj &&
        this.reportedRTDuetDefectObj?.equipmentStops?.length > 0
      ) {
        return (
          this.reportedRTDuetDefectObj?.totalEquipmentStops +
          ' / ' +
          this.reportedRTDuetDefectObj?.totalDownTime
        );
      } else {
        const noOfStops = safetyItem?.controls?.find(
          (item: any) => item.controlname === 'es-stopno-val'
        );
        const totalDownTime = safetyItem?.controls?.find(
          (item: any) => item.controlname === 'es-downtime-val'
        );
        return (
          (noOfStops ? noOfStops.response : 0) +
          ' / ' +
          (totalDownTime ? totalDownTime.response : 0)
        );
      }
    } else {
      return safetyItem ? safetyItem.controls.length : 0;
    }
  }

  private addUniqueIds() {
    const questionDivs = this.el.nativeElement.querySelectorAll('div.qn-question');
    questionDivs.forEach((div: HTMLElement, index: number) => {
      const uniqueId = `qn-question-${index + 1}`;
      div.setAttribute('id', uniqueId);
    });
    const answerDivs = this.el.nativeElement.querySelectorAll('div.qn-answer');
    answerDivs.forEach((div: HTMLElement, index: number) => {
      const uniqueId = `qn-answer-${index + 1}`;
      div.setAttribute('id', uniqueId);
    });
}

  formateDate(date:string){
    if(this.selectedLanguage == 'fr'){
      moment.locale('fr');
      return moment(date).format('MMM DD, YYYY');
    }else{
      return moment(date).format('MMM DD, YYYY');
    }
  }
}
