<div id="logout-status" class="alert-flex-{{isAutoLogout}}">
  <div> 
    <i id="circle-remove-nt" class="bi bi-info-circle" (click)="removeNotification()"></i>
    {{ "app.shift_position_selection.logged_out_handoff_submitted" | translate }}
  </div>
  <div>
    <i id="remove-notification" class="bi bi-x-lg" (click)="removeNotification()"></i>
  </div>
</div>
<app-user-settings [userDetailsInfo]="userDetailsData"></app-user-settings>
<div class="choose-shift-position align-items-center justify-content-center">
  <img id="img-logo" src="./../../../../../assets/images/mccainLogo1.jpg" class="logo-img" alt="SVG Icon" />
  <div class="shift-location">
    <span id="shift-location" class="shift-loc-label">{{ "app.shift_position_selection.location" | translate }}
    </span>
    @if (selectedPlantCode != '') {<span id="plant-code" class="shift-plant-code">{{
      selectedPlantCode 
      }}</span>}
  </div>
  <div id="shift-greet" class="shift-greetings">{{"app.shift_position_selection.greet_user" | translate }} {{ userName }}!</div>
  <div id="shift-greeting-guide" class="shift-start-guide">
    {{ "app.shift_position_selection.select_shiftposition_text" | translate }}
  </div>

  <!-- Login Form Start -->
  <form id="login-form" [formGroup]="loginForm" class="form-group" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="shift-position-label">
      <label id="shift-pos-label" for="shiftPosition" class="form-label">
        {{ "app.shift_position_selection.shift_position" | translate }}
      </label>
    </div>
    <div id="shift-pos-dropdown" class="shift-select-drpdwn">
      <ng-multiselect-dropdown id="select-shiftPosition" formControlName="shiftPosition"  [placeholder]="'app.shift_position_selection.select' | translate" [settings]="dropdownSettings"
        [data]="shiftPositionList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)">
      </ng-multiselect-dropdown>
    </div>

    <button id="submit_btn" class="shift-confirm" type="submit" [disabled]="!loginForm.valid">
      {{ "app.shift_position_selection.confirm" | translate }}
    </button>
  </form>

  <!-- Login Form End -->
</div>