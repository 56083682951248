export function getCurrentShiftDetails(shiftInfoData: any) {
    let shiftInfo: any = {};
    if (shiftInfoData && shiftInfoData.result) {
        shiftInfo.id = shiftInfoData.result.id;
        shiftInfo.currShiftUtcStartTimeEpoch = shiftInfoData.result.currShiftUtcStartTimeEpoch;
        shiftInfo.currShiftUtcEndTimeEpoch = shiftInfoData.result.currShiftUtcEndTimeEpoch;
        shiftInfo.currShiftPlantStartTimeEpoch = shiftInfoData.result.currShiftPlantStartTimeEpoch;
        shiftInfo.currShiftPlantEndtimeEpoch = shiftInfoData.result.currShiftPlantEndtimeEpoch;
        return shiftInfo;
    } else {
        return null;
    }
}
