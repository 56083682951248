import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  userProfileInfo: BehaviorSubject<any> = new BehaviorSubject({});
  pisitionInfoList: BehaviorSubject<any> = new BehaviorSubject({});
  saveStatusEvent: BehaviorSubject<any> = new BehaviorSubject({});
  autoLogoutStatus: BehaviorSubject<any> = new BehaviorSubject({});
  alertMessageSubject = new Subject<{}>();
  alertMessage$ = this.alertMessageSubject.asObservable();

  constructor() {
  }

  pushAlertMessage(message: string,messageType:string) {
    const messageObj = {
      message: message,
      type:messageType
    }
    this.alertMessageSubject.next(messageObj);
  }
}
