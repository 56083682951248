import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageSelectionService {
  constructor(private translate: TranslateService) { }
  setLanguage(language: string){
    this.translate.use(language);
    sessionStorage.setItem('selectedLanguage',language);
  }
}
