export function getPositionList(shiftPositionData: any) {
  if (shiftPositionData && shiftPositionData.result.length > 0) {
    let positionList = [];
    for (let item of shiftPositionData.result) {
      positionList.push({
        id: item.id,
        value: item.code,
        name: item.name,
        text: item.name,
      });
    }
    return positionList;
  } else {
    return [];
  }
}
