import { environment } from '../../environments/environment';

export const API_URL = environment.apiUrl;
export const AUTH_FEATURE = 'auth';
export const SETTINGS_SUBFEATURE = 'Settings';
export const USER_SUBFEATURE = 'User';
export const DEFAULT_LANG = 'en';
export const ENVIRONMENT_CODE = environment.envCode;
export const ENVIRONMENT_V = environment.appVersion;
export const ENVIRONMENT_PROD = 'PROD';
export const ENVIRONMENT_QA = 'QA';
export const ENVIRONMENT_PRE_PROD = 'PRE PROD';
export const ENVIRONMENT_DEV = 'DEV';
export const KPI_VIEW = 'KPI';
export const RECAP = 'Recap';
export const ACTIONLOG = 'Action Log';
export const SUCCESS_STATUS_CODE =200;
export const ERROR_STATUS_CODE =400;
export const SUCCESS_TRUE = true;
export const SUCCESS_FALSE = false;
export const IS_SETTINGS = true;
export const GETUSER_API = 'GetUserDetails';
export const GETTOKEN_API = 'getAPItoken';
