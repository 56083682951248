import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import * as APP_CONST from '../../../app/constants/app.constants';
import { getHttpHeaders } from '../../http/header-config';
import { UserDetails } from '../../core/models/user-details';
import { getHttpBodyData } from '../../http/body-config';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  // API endpoint
  private http = inject(HttpClient);

  constructor() {}

  ///// get data from the API
  getLanguages(
    feature: string,
    subFeature: string,
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetLanguages?plantId=${userDetails.plantId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getUserDetails(
    feature: string,
    subFeature: string,
    languageCode: string,
    loginName: string
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetUserDetails`,
      getHttpHeaders({
        languageCode: languageCode,
        loginName: loginName
      })
    );
  }

  getAPItoken(
    feature: string,
    subFeature: string,
    userDetails:any,
    expiredToken:any,
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/getAPItoken?plantId=${userDetails.plantId}`,
      getHttpHeaders({ 
        loginName: userDetails.loginName,
        token: expiredToken
      })
    );
  }

  getPositions(
    feature: string,
    subFeature: string,
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetPositions?plantId=${userDetails.plantId}&roleIds=${userDetails.roleId}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  saveShiftPosition(
    feature: string,
    subFeature: string,
    userDetails: UserDetails,
    userName:any,
    isSettings:boolean
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/SaveUserConfiguration`,
      getHttpBodyData({
        userId: userDetails.userId,
        preLanguageCode: userDetails.languageCode,
        prePlantId: userDetails.plantId,
        prePositionId1: userDetails.positionId1,
        prePositionId2: userDetails.positionId2,
        prePositionId3: userDetails.positionId3,
        UserName:userName,
        isSettings:isSettings
      }),

      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  getLoggedInUsers(
    feature: string,
    subFeature: string,
    userDetails: UserDetails,
    pageNumber:number,
    pageSize:number
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetLoggedInUsers?plantId=${userDetails.plantId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      getHttpHeaders({
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    );
  }
  
  signOutFromPosition(
    feature: string,
    subFeature: string,
    userDetails: UserDetails,
    userId:string,
    positionId:string
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/SignOutUserFromGivenPosition?plantId=${userDetails.plantId}&userId=${userId}&positionId=${positionId}`,
      getHttpBodyData({ 
      }),
      getHttpHeaders({ 
        loginName: userDetails.loginName
      })
    );
  }

  getUserSettingsInfo(
    feature: string,
    subFeature: string,
    userDetails: UserDetails,
    positionIds:any[],
  ): Observable<any> {

    let position = '';

    positionIds.forEach((element:any) => {
      position +=  `&positionIds=${element}`;
    }); 
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetUserSettingsInfo?plantId=${userDetails.plantId}${position}`,
      getHttpHeaders({ 
        languageCode: userDetails.languageCode,
        loginName: userDetails.loginName
      })
    )
  }

  getActivePositions(
    feature: string,
    subFeature: string,
    languageCode: string,
    userDetails: any
  ): Observable<any> {
    return this.http.get(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/GetActivePositions?plantId=${userDetails.plantId}&userId=${userDetails.userId}`,
      getHttpHeaders({
        languageCode: languageCode,
        loginName: userDetails.loginName
      })
    );
  }

  logout(
    feature: string,
    subFeature: string,
    userDetails: UserDetails
  ): Observable<any> {
    return this.http.post(
      `${APP_CONST.API_URL}/${feature}/api/${subFeature}/LogOut?plantId=${userDetails.plantId}&userId=${userDetails.userId}`,
      getHttpBodyData({ 
      }),
      getHttpHeaders({ 
        loginName: userDetails.loginName
      })
    );
  }
}
