<div class="modal-validation-container">
    <div class="modal-header">
      @if(status != "success"){
        <img src="../../../../../assets/images/error-round.svg" class="error-round-img" alt="SVG Icon" />
      }
      <h5 class="modal-title" id="msg_modal_title">{{ title }}</h5>
    </div>
    <div id="modal_body" class="modal-body" [innerHTML]=message></div>
    <div class="modal-footer">
      <button id="close_msg_btn" type="button" class="confirm_btn" (click)="onClose()">
        {{ ok }}
      </button> 
    </div>
  </div>
  