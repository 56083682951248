<div class="handoff-container">


    <div class="handoff-container delivery-prev custom-people-{{selectedPlantCode}}-{{positionCode}}">
        @if(selectedPlantCode == 'CDL')
        {
        @for(item of peopleResponse; track item; let i= $index) {
        @for (controlItem1 of peopleResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "people-attendance") {
        <div class="row">
            <div class="col-6">
                <label class="label">{{controlItem1.labeltext}}</label>
            </div>
        </div>
        <div class="row comments-section m-0">
            <div class="col-6 response-sec">
                <p class="mb-0">{{controlItem1.response}}</p>
            </div>
        </div>
        }
        }
        }
        <div class="row spacing-pt-20 spacing-pb-20">
            <div class="col-6 items-section">

                <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>

                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                === 'number') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                        "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                        controlItem1.controlname === "delivery-target-shift") {
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">{{controlItem1.response}}</p>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                @if(controlItem1.unit === 'KG/HR') {
                                <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                                } @else if(controlItem1.unit === "MIN") {
                                <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                                } @else if(controlItem1.unit === "LBS") {
                                <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                                }
                            </div>

                        </div>

                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                    </div>
                </div>
                }@else if(controlItem1.ctrltype === 'select') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        <p class="mb-0">{{controlItem1.response}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                    </div>
                </div>
                }
                }
                }
            </div>
            <div class="col-6 comments-section">
                <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i+1]; track controlItem1) {
                @if(controlItem1.ctrltype === 'text') {
                <div class="row spacing-pt-20">
                    <div class="col"></div>
                </div>

                <div class="row spacing-pt-20">
                    <div class="col response-sec response-style">
                        <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                    </div>
                </div>

                }
                }
                }

            </div>

        </div>
        }
        @else if(selectedPlantCode == 'FVL')
        {
        <div class="row mb-4 mt-2 ">
            <div class="col-6 items-section">
                @if(peopleResponse.length === 1) {
                @for(item of peopleResponse[0]; track item; let i=$index) {
                @if(item.ctrltype === 'text') {
                <div class="row">
                    <div class="col">
                        <label class="label">{{item.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec">

                        <p class="mb-0">{{item.response}}</p>


                    </div>
                </div>
                }
                }
                }
            </div>
        </div>
        } @else if(selectedPlantCode == 'BUR' && positionCode === 'PSA'){
        <div class="row spacing-pt-20 spacing-pb-20">
            <div class="col-6 items-section">
                <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                === 'number' || controlItem1.ctrltype === 'text') {
                @if(controlItem1.controlname === "people-attendance" || controlItem1.controlname === "people-hire" ||
                controlItem1.controlname === "visitors-today") {

                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec d-flex">

                        <p class="mb-0">{{controlItem1.response}}</p>
                        <div class="col d-flex justify-content-end ">
                            @if(controlItem1.unit === "PEOPLE") {
                            <span class="unit-bg">PEOPLE</span>
                            } @else if(controlItem1.unit === "ABSENCES") {
                            <span class="unit-bg">ABSENCES</span>
                            }
                        </div>

                    </div>
                </div>
                }
                }
                }
                }
            </div>
            <div class="col-6 comments-section">
                <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i+1]; track controlItem1) {
                @if(controlItem1.ctrltype === 'text') {
                @if(!(controlItem1.controlname === "people-attendance" || controlItem1.controlname === "people-hire" ||
                controlItem1.controlname === "visitors-today")) {

                <div class="row spacing-pt-20">
                    <div class="col"></div>
                </div>

                <div class="row spacing-pt-20">
                    <div class="col response-sec response-style">
                        <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                    </div>
                </div>
                }
                }
                }
                }

            </div>

        </div>
        }
        @else
        {
        @for(item of peopleResponse; track item; let i= $index) {
        @for (controlItem1 of peopleResponse[i]; track controlItem1) {
        @if(controlItem1.ctrltype === 'text' && controlItem1.controlname === "people-attendance") {
        <div class="row">
            <div class="col-6">
                <label class="label">{{controlItem1.labeltext}}</label>
            </div>
        </div>
        <div class="row comments-section m-0">
            <div class="col-6 response-sec">
                <p class="mb-0">{{controlItem1.response}}</p>
            </div>
        </div>
        }
        }
        }
        <div class="row spacing-pt-20 spacing-pb-20">
            <div class="col-6 items-section">
                @if(selectedPlantCode === 'MAT' && (positionCode==='FLS' || positionCode === 'SLS' || positionCode ===
                'PLH') || (selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'SLS'  || positionCode === 'PLH1' || positionCode === 'PLH2' ))){
                <h3 class="custom-{{selectedPlantCode}}-{{positionCode}}"></h3>
                }
                @else{
                <h3 class="m-0">{{ "app.previous_hoff.items" | translate }}</h3>
                }
                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'rdbutton' || controlItem1.ctrltype === 'date' || controlItem1.ctrltype
                === 'number' || (selectedPlantCode === 'HAR' && (positionCode === 'FLS1' || positionCode === 'FLS2' || positionCode === 'SLS' || positionCode === 'PLH1' || positionCode === 'PLH2' ) && (controlItem1.controlname ===
                'information-general' || controlItem1.controlname === 'people-additional' || controlItem1.controlname === 'people-additional' || !controlItem1.controlname.includes('-cmt')))) {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number') {
                        @if(controlItem1.controlname === "delivery-current-line-rate" || controlItem1.controlname ===
                        "delivery-changeover-duration" || controlItem1.controlname === "delivery-changeover-linerate" ||
                        controlItem1.controlname === "delivery-target-shift" || controlItem1.controlname ===
                        "people-attendance" || controlItem1.controlname === "people-temps") {
                        <div class="row">
                            <div class="col">
                                <p class="mb-0">{{controlItem1.response}}</p>
                            </div>
                            <div class="col d-flex justify-content-end ">
                                @if(controlItem1.unit === 'KG/HR') {
                                <span class="unit-bg">{{ "app.previous_hoff.kg_hr" | translate }}</span>
                                } @else if(controlItem1.unit === "MIN") {
                                <span class="unit-bg">{{ "app.previous_hoff.min" | translate }}</span>
                                } @else if(controlItem1.unit === "LBS") {
                                <span class="unit-bg">{{ "app.previous_hoff.lbs" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "ABSENCES") {
                                <span class="unit-bg">{{ "app.previous_hoff.absences" | translate }}</span>
                                }
                                @else if(controlItem1.unit === "PEOPLE") {
                                <span class="unit-bg">{{ "app.previous_hoff.people" | translate }}</span>
                                }
                            </div>

                        </div>

                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                    </div>
                </div>
                }@else if(controlItem1.ctrltype === 'select') {
                <div class="row spacing-pt-20">
                    <div class="col">
                        <label class="label">{{controlItem1.labeltext}}</label>
                    </div>

                </div>
                <div class="row  m-0">
                    <div class="col response-sec">
                        @if(controlItem1.ctrltype === 'date' || controlItem1.ctrltype === 'number' ||
                        controlItem1.ctrltype === 'text') {
                        <p class="mb-0">{{controlItem1.response}}</p>
                        } @else {
                        <p class="mb-0">{{controlItem1.response}}</p>

                        }
                    </div>
                </div>
                }
                }
                }
            </div>
            <div class="col-6 comments-section">
                @if(selectedPlantCode === 'MAT' && (positionCode==='FLS' || positionCode === 'SLS' || positionCode ===
                'PLH') || (selectedPlantCode==='HAR' && (positionCode==='FLS1' || positionCode === 'FLS2' || positionCode === 'SLS' || positionCode === 'PLH1' || positionCode === 'PLH2'))){
                <h3 class="custom-{{selectedPlantCode}}-{{positionCode}}"></h3>
                }
                @else{
                <h3 class="m-0">{{ "app.previous_hoff.comments" | translate }}</h3>
                }
                @for(item of peopleResponse; track item; let i= $index) {
                @for (controlItem1 of peopleResponse[i]; track controlItem1) {
                @if(controlItem1.ctrltype === 'text') {
                @if(selectedPlantCode === 'HAR' && (positionCode === 'FLS1' || positionCode === 'FLS2' || positionCode === 'SLS' || positionCode === 'PLH1' || positionCode === 'PLH2')){
                @if((controlItem1.controlname !== 'information-general' && controlItem1.controlname !==
                'people-additional'  && controlItem1.controlname !== 'people-flakes-common-comment'|| (controlItem1.controlname.includes('-cmt') ))){
                    <div class="row spacing-pt-20">
                        <div class="col"></div>
                    </div>
    
                    <div class="row spacing-pt-20 custom-class-{{controlItem1.controlname}}">
                        <div class="col response-sec response-style">
                            <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                        </div>
                    </div>
                }
                }
                @else{
                <div class="row spacing-pt-20">
                    <div class="col"></div>
                </div>

                <div class="row spacing-pt-20 custom-class-{{controlItem1.controlname}}">
                    <div class="col response-sec response-style">
                        <p class="mb-0">{{controlItem1.response ? controlItem1.response: ' '}}</p>
                    </div>
                </div>
                }
                }
                }
                }

            </div>

        </div>
        }
    </div>


</div>