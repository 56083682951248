import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

export function faroInitializer(): Function {
  return async () => {
    initializeFaro({
      url: 'https://faro-collector-prod-ca-east-0.grafana.net/collect/4864d60f3075f12d92db2f1e0d925caf',
      // apiKey: 'secret',
      app: {
        name: 'RUM telemetry for MDI app',
        version: '1.0.0',
      },
      instrumentations: [
        // Mandatory, omits default instrumentations otherwise.
        ...getWebInstrumentations(), //{
          // captureConsole: true,
          // captureConsoleDisabledLevels: [],
        // }),
        // Tracing package to get end-to-end visibility for HTTP requests.
        new TracingInstrumentation(),
      ],
    });
  };
}
