<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas" data-bs-keyboard="false" data-bs-backdrop="true">
  <div class="offcanvas-header">
    <h6 class="offcanvas-title d-none d-sm-block" id="offcanvas">
      <span id="user-set" class="user-settings">{{ "app.user_settings.title" | translate }}
      </span>
    </h6>
    <button id="txt-reset" type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body px-0 mt-5">
    <form [formGroup]="userSettingsForm" (ngSubmit)="onUserSubmit()" autocomplete="off">
      <div class="mb-3 field-margins">
        <label id="plant-label" for="plant" class="form-label usr-settings-label">{{ "app.user_settings.plant" | translate }}</label>
        <ng-multiselect-dropdown id="plant" [placeholder]="'Plant'" [settings]="plantSettings"
          [disabled]="plantSettings.disabled" [data]="plantList" (onSelect)="onPlantSelection($event)"
          formControlName="plant" [(ngModel)]="selectedPlant">
        </ng-multiselect-dropdown>
      </div>
      <div class="mb-3 field-margins pointerNone">
        <label id="usr-settings-label" for="language" class="form-label usr-settings-label">{{ "app.user_settings.language" | translate
          }}</label>        
        <ng-multiselect-dropdown id="language" [placeholder]="'Language'" [settings]="languageSettings"
          [data]="languageList" (onSelect)="onItemSelect($event)" formControlName="language"
          [(ngModel)]="selectedLanguage"  [disabled]="true" >
        </ng-multiselect-dropdown>
      </div>
      <button id="user_btn_confirm" type="submit" class="submit-button" aria-label="Close" data-bs-dismiss="offcanvas"
        [disabled]="!userSettingsForm.valid " (click)="onUserSubmit()">
        <span id="confirm-text" class="confirmText">{{ "app.user_settings.confirm" | translate }}</span>
      </button>
    </form>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col min-vh-100">
      <!-- toggler -->
      <div id="setting-sec" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" (click)="onNavigationOpen()" class="settings-section">
        <img id="img-icon" src="./../../../../../assets/images/hamburgerMenu.svg" height="17px" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvas" alt="SVG Icon" />
        <span id="setting-t" class="settings-title">{{ "app.user_settings.settings" | translate }}</span>
      </div>
    </div>
  </div>
</div>