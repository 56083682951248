import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-people-previous',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './people-previous.component.html',
  styleUrl: './people-previous.component.scss'
})
export class PeoplePreviousComponent {
  @Input() peopleResponse: any;
  @Input() selectedPlantCode: any;
  @Input() positionCode: any;

}
