export const environment = {
    production: false,
    baseURL: 'https://jsonplaceholder.typicode.com/',
    apiKey:'', // is  used to authenticate the user
    apiUrl: 'https://dev-digital-manufacturing.mccain.com/mdiaiapidev',
    imgUrl: 'https://image.tmdb.org/t/pw500',
    mockApiUrl: 'http://localhost:3000',
    envCode: 'DEV',
    appVersion: '1.808',
    //clientId: '96d498bd-a80c-4280-975e-fba82cd4b7a7',
    clientId: '9c5751f4-006f-41ba-82a8-38b4f42aa488',
    tenantId: '59fa7797-abec-4505-81e6-8ce092642190',
    redirectUri:  'https://dev-digital-manufacturing.mccain.com',//'http://localhost:4200/',
    postLogoutRedirectUri:  'https://dev-digital-manufacturing.mccain.com/logout',
    appInsightInstrumentationKey: '2f2b1206-1f33-4717-8630-9f3f0c3cdebe'
}
