<div id="validation-container" class="modal-validation-container">
    <div id="popup_header" class="modal-header">
      <h5 class="modal-title" id="current_handoff_popup">{{ title }}</h5>
    </div>
    <div id="popup_body" class="modal-body" [innerHTML]=message></div>
    <div id="popup_footer" class="modal-footer">
      <button id="close_btn_popup" type="button" class="confirm_close" (click)="onClose()">
        {{ cancel }}
      </button> 
      <button id="sub_btn_popup" type="button" class="confirm_btn" (click)="onSubmit()">
        {{ ok }}
      </button> 
    </div>
  </div>
  